import React, { useState } from 'react';
import SmallFormProlong from './SmallFormProlong';
import SmallFormRenew from './SmallFormRenew';

const RightSide = () => {
  const [isRen, setIsRen] = useState(true);
  const handleSwitcherChanged = (e: any) => {
    setIsRen(e.target.checked);
  };
  const handleSwitchToRen = () => {
    setIsRen(true);
  };
  const handleSwitchToPro = () => {
    setIsRen(false);
  };

  return (
    <div className='right-side'>
      <h2>Contacter un conseiller pour personnaliser votre&nbsp;:</h2>
      <div className='switcher-container'>
        <span
          onClick={handleSwitchToRen}
          className='ren'
          style={isRen ? { opacity: 1 } : { opacity: 0.25 }}
        >
          renouvellement
        </span>
        <input
          checked={isRen}
          onChange={handleSwitcherChanged}
          className='switcher'
          type='checkbox'
        />
        <span
          onClick={handleSwitchToPro}
          className='pro'
          style={isRen ? { opacity: 0.25 } : { opacity: 1 }}
        >
          prolongation
        </span>
      </div>
      {isRen ? <SmallFormRenew /> : <SmallFormProlong />}
    </div>
  );
};

export default RightSide;
