const isDev = process.env.REACT_APP_ENV === 'development';

const numberPlateFormat = () => {
  const immInput = document.getElementById(
    'imm-input'
  ) as HTMLInputElement | null;
  if (immInput) {
    immInput.addEventListener('keypress', function (event) {
      if (this.value.length >= 9) {
        event.preventDefault();
      }
    });

    immInput.addEventListener('keyup', function (event) {
      const val: string = this.value;
      const keyCode = event.code;
      const length = val.length;
      this.value = val.toUpperCase();
      if (
        (length == 2 || length == 6) &&
        !(keyCode == 'Backspace' || keyCode == 'Delete')
      ) {
        this.value = val + '-';
      }
    });
  }
};
const radioUncheck = (getValues: any, setValue: any): void => {
  const radios = document.querySelectorAll('[type="radio"]');
  radios.forEach((radio) => {
    function unCheck(e: any) {
      const values = getValues();
      if (values[e.target.name] === e.target.value) {
        e.target.checked = false;
        setValue(e.target.name, null);
      }
    }
    radio.removeEventListener('click', unCheck);
    radio.addEventListener('click', unCheck);
  });
};

const parseGET = (param: any) => {
  const searchStr: any = document.location.search;

  try {
    const match: any = searchStr.match('[?&]' + param + '=([^&]+)');

    if (match) {
      let result = match[1];

      result = result.replace(/\+/g, '%20');

      result = decodeURIComponent(result);

      return result;
    } else {
      return '';
    }
  } catch (e) {
    return '';
  }
};
const setUTMValues = () => {
  const utm_source = document.getElementById('utm_source') as HTMLInputElement;
  const utm_medium = document.getElementById('utm_medium') as HTMLInputElement;
  const utm_campaign = document.getElementById(
    'utm_campaign'
  ) as HTMLInputElement;
  const utm_term = document.getElementById('utm_term') as HTMLInputElement;
  const utm_content = document.getElementById(
    'utm_content'
  ) as HTMLInputElement;

  const UTM_PARAMS = sessionStorage.utm_params
    ? JSON.parse(sessionStorage.utm_params)
    : null;

  utm_source.value = UTM_PARAMS?.utm_source;
  utm_medium.value = UTM_PARAMS?.utm_medium;
  utm_campaign.value = UTM_PARAMS?.utm_campaign;
  utm_term.value = UTM_PARAMS?.utm_term;
  utm_content.value = UTM_PARAMS?.utm_content;
};

export { radioUncheck, numberPlateFormat, parseGET, setUTMValues, isDev };
