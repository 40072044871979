import React from "react";
import PageWrapper from "../components/PageWrapper";
import Head from "../components/head";
import Grid from "../components/grid";
import {Helmet} from "react-helmet";

export default function PrivacyPage() {
  return (
    <div className="page-privacy">
      <Helmet>
        <title>Politique de confidentialité - Click & Fi</title>
        <meta name="description" content="En savoir plus sur notre politique de confidentialité et notre engagement en matière de protection des données personnelles. Nous sommes déterminés à offrir les meilleures solutions de location et de financement pour vos projets auto" />
        <link rel="canonical" href="https://www.click-and-fi.fr/politique-de-confidentialite" />
      </Helmet>
      <PageWrapper>
        <Head>
          <b>Politique de confidentialité</b>
        </Head>
        <Grid container spacing={4} maxWidth="lg">
          <Grid sm={12} className="text-privacy">
            <div>
              <p>
                La pr&eacute;sente politique de confidentialit&eacute; a pour
                vocation de vous aider &agrave; comprendre comment sont
                collect&eacute;es, trait&eacute;es et conserv&eacute;es vos
                donn&eacute;es personnelles par SIMPLY-FY via le Site.
              </p>
            </div>
            <div>
              <p>
                SIMPLY-FY comprend que la protection des donn&eacute;es et de la
                vie priv&eacute;e soit un enjeu pour l&rsquo;ensemble des
                utilisateurs visitant le Site.&nbsp;
              </p>
            </div>
            <div>
              <p>
                SIMPLY-FY s&rsquo;engage, conform&eacute;ment &agrave; la
                r&egrave;glementation RGPD, &agrave; respecter votre vie
                priv&eacute;e et &agrave; prot&eacute;ger vos donn&eacute;es
                &agrave; caract&egrave;re personnel, c&rsquo;est &agrave; dire
                susceptible de vous identifier directement ou indirectement en
                tant que personne.
              </p>
            </div>
            <div>
              <p>
                L&rsquo;objectif de la pr&eacute;sente politique de
                confidentialit&eacute; est de vous pr&eacute;senter les
                engagements de SIMPLY-FY en la mati&egrave;re.
              </p>
            </div>
            <div>
              <p>
                <strong>DEFINITION</strong>
              </p>
            </div>
            <div>
              <p>
                Lors de votre utilisation de notre Site, nous pouvons &ecirc;tre
                amen&eacute;s &agrave; vous demander de nous communiquer des
                donn&eacute;es &agrave; caract&egrave;re personnel vous
                concernant.
              </p>
            </div>
            <div>
              <p>
                Le terme &laquo;{" "}
                <em>donn&eacute;es &agrave; caract&egrave;re personnel</em>{" "}
                &raquo; d&eacute;signe toutes les donn&eacute;es qui permettent
                d&rsquo;identiﬁer un individu, ce qui correspond notamment
                &agrave; vos nom, pr&eacute;noms, pseudonyme, photographie,
                adresse postale et de courrier &eacute;lectronique,
                intitul&eacute; de votre poste, num&eacute;ros de
                t&eacute;l&eacute;phone, date de naissance, donn&eacute;es
                relatives &agrave; vos transactions sur le Site, d&eacute;tails
                de vos achats, num&eacute;ros de carte bancaire, SIRET,
                num&eacute;ro de TVA intracommunautaire, adresse IP, ainsi
                qu&rsquo;&agrave; tout autre renseignement que vous choisirez de
                nous communiquer &agrave; votre sujet.
              </p>
            </div>
            <div>
              <p>
                <strong>OBJET</strong>
              </p>
            </div>
            <div>
              <p>
                La pr&eacute;sente charte (ci-apr&egrave;s la &laquo;{" "}
                <strong>Charte</strong> &raquo;) a pour objet de vous informer
                sur les moyens que nous mettons en &oelig;uvre pour collecter
                vos donn&eacute;es &agrave; caract&egrave;re personnel, dans le
                respect le plus strict de vos droits.
              </p>
            </div>
            <div>
              <p>
                Nous vous indiquons &agrave; ce sujet que nous nous conformons,
                dans la collecte et la gestion de vos donn&eacute;es &agrave;
                caract&egrave;re personnel, &agrave; la loi n&deg; 78-17 du 6
                janvier 1978 relative &agrave; l&apos;informatique, aux ﬁchiers et
                aux libert&eacute;s, dans sa version actuelle (ci-apr&egrave;s :
                la &laquo; Loi Informatique et Libert&eacute;s &raquo;), ainsi
                que le r&egrave;glement (UE) 2016/679 du 27 avril 2016 relatif
                &agrave; la protection des personnes physiques &agrave;
                l&apos;&eacute;gard du traitement des donn&eacute;es &agrave;
                caract&egrave;re personnel et &agrave; la libre circulation de
                ces donn&eacute;es (ci- apr&egrave;s : le &laquo; 
                <strong>RGPD </strong>&raquo;).
              </p>
            </div>
            <div>
              <p>
                <strong>
                  IDENTITE DU RESPONSABLE DE LA COLLECTE DES DONNEES
                </strong>
              </p>
            </div>
            <div>
              <p>
                Le responsable de la collecte de vos donn&eacute;es &agrave;
                caract&egrave;re personnel est la soci&eacute;t&eacute;
                SYMPLY-FY , immatricul&eacute;e au Registre du Commerce et des
                Soci&eacute;t&eacute;s de Dijon sous le n&deg; 949 846 653,
                ayant son si&egrave;ge social au 8F, rue Jeanne Barret &ndash;
                21000 Dijon (d&eacute;nomm&eacute;e ci-apr&egrave;s : &laquo;{" "}
                <strong>Nous </strong>&raquo;).
              </p>
            </div>
            <div>
              <p>
                <strong>COLLECTE DES DONNEES A CARACTERE PERSONNEL</strong>
              </p>
            </div>
            <div>
              <div>
                <p>
                  Le fondement l&eacute;gal de notre collecte de vos
                  donn&eacute;es personnelles est le suivant :
                </p>
              </div>
              <div>
                <ul>
                  <li>
                    cette collecte est n&eacute;cessaire aﬁn
                    d&rsquo;ex&eacute;cuter le contrat conclu lorsque vous
                    utilisez nos services sur notre Site ;
                  </li>
                  <li>
                    l&rsquo;int&eacute;r&ecirc;t l&eacute;gitime lorsque vous
                    nous fournissez volontairement des donn&eacute;es &agrave;
                    caract&egrave;re personnel lors de votre visite sur notre
                    Site, les donn&eacute;es &eacute;tant alors
                    collect&eacute;es pour nous permettre de mieux
                    r&eacute;pondre &agrave; vos demandes d&rsquo;information
                    sur nos services.
                  </li>
                </ul>
              </div>
              <div>
                <p>
                  Vos donn&eacute;es &agrave; caract&egrave;re personnel sont
                  collect&eacute;es pour r&eacute;pondre &agrave; une ou
                  plusieurs des ﬁnalit&eacute;s suivantes :
                </p>
              </div>
            </div>
            <div>
              <div>
                <ul>
                  <li>
                    G&eacute;rer votre acc&egrave;s &agrave; certains services
                    accessibles sur le Site et leur utilisation,
                  </li>
                  <li>
                    Eﬀectuer les op&eacute;rations relatives &agrave; la gestion
                    des Clients concernant les contrats, factures, suivide la
                    relation avec les Clients,
                  </li>
                  <li>
                    Constituer un ﬁchier d&rsquo;utilisateurs, de clients et
                    prospects,
                  </li>
                  <li>
                    Adresser des newsletters, sollicitations et messages
                    promotionnels. Dans le cas o&ugrave; vous ne le souhaiteriez
                    pas, nous vous donnons la facult&eacute; d&rsquo;exprimer
                    votre refus &agrave; ce sujet lors de la collecte de vos
                    donn&eacute;es,
                  </li>
                  <li>
                    &Eacute;laborer des statistiques commerciales et de
                    fr&eacute;quentation de nos services,
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div>
                <ul>
                  <li>
                    Organiser des jeux concours, loteries et toutes
                    op&eacute;rations promotionnelles &agrave; l&rsquo;exclusion
                    des jeux d&rsquo;argent et de hasard en ligne soumis
                    &agrave; l&rsquo;agr&eacute;ment de l&rsquo;Autorit&eacute;
                    de R&eacute;gulation des Jeux en ligne
                  </li>
                  <li>
                    G&eacute;rer la gestion des avis des personnes sur des
                    produits,services ou contenus,
                  </li>
                  <li>
                    G&eacute;rer les impay&eacute;s et les contentieux
                    &eacute;ventuels quant &agrave; l&rsquo;utilisation de nos
                    produits et services,
                  </li>
                  <li>
                    Personnaliser les r&eacute;ponses &agrave; vos demandes
                    d&rsquo;information,
                  </li>
                  <li>
                    Respecter nos obligations l&eacute;gales et
                    r&eacute;glementaires. Nous vous informons, lors de la
                    collecte de vos donn&eacute;es personnelles, si certaines
                    donn&eacute;es doivent &ecirc;tre obligatoirement
                    renseign&eacute;es ou si elles sont facultatives (par des
                    ast&eacute;risques visibles sur le Site).&nbsp;
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <div>
                            <strong>
                              Pourquoi collectons-nous vos donn&eacute;es
                              personnelles ?
                            </strong>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <strong>
                              Quelles sont les cat&eacute;gories de
                              donn&eacute;es personnelles que nous collectons
                              &agrave; cette fin ?
                            </strong>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <strong>
                              Quelle est la base juridique nous autorisant
                              &agrave; collecter vos donn&eacute;es personnelles
                              pour une telle finalit&eacute; ?
                            </strong>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div>
                            Nous collectons vos donn&eacute;es personnelles afin
                            de vous identifier lors de l&rsquo;utilisation de
                            notre site et afin de vous adresser des messages
                            li&eacute;s &agrave; la bonne administration de
                            notre site (ex : confirmation d&rsquo;inscription,
                            modification du site ou des conditions
                            d&rsquo;utilisation etc&hellip;)
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <div>
                              <table>
                                <tbody>
                                  <tr></tr>
                                  <tr>
                                    <td>
                                      <div>
                                        <div>
                                          <strong>
                                            Donn&eacute;es
                                            d&rsquo;identification
                                          </strong>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        <div>
                                          <strong> Donn&eacute;es de connexion</strong>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            Ce traitement a pour base juridique
                            l&rsquo;ex&eacute;cution du contrat nous liant lors
                            de votre acceptation de nos conditions
                            g&eacute;n&eacute;rales au moment de la
                            cr&eacute;ation de son compte
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div>
                            Nous collectons vos donn&eacute;es personnelles afin
                            de vous livrer votre commande/service.
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <div>
                              <table>
                                <tbody>
                                  <tr></tr>
                                  <tr>
                                    <td>
                                      <div>
                                        <div>
                                          <strong>
                                            {" "}
                                            Donn&eacute;es
                                            d&rsquo;identification
                                          </strong>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            Ce traitement a pour base juridique
                            l&rsquo;ex&eacute;cution du contrat nous liant lors
                            de votre acceptation de nos conditions
                            g&eacute;n&eacute;rales au moment de la
                            cr&eacute;ation de son compte
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div>
                            Nous collectons vos donn&eacute;es personnelles afin
                            d&rsquo;am&eacute;liorer et optimiser notre site,
                            par exemple en &eacute;tudiant votre comportement
                            lors de votre visite et d&rsquo;en tirer des
                            cons&eacute;quences afin d&rsquo;apporter des
                            modifications en termes d&rsquo;ergonomie et
                            exp&eacute;rience utilisateur.
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <div>
                              <table>
                                <tbody>
                                  <tr></tr>
                                  <tr>
                                    <td>
                                      <div>
                                        <div>
                                          <strong>
                                            Donn&eacute;es d&rsquo;utilisation
                                            et consommation
                                          </strong>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        <div>

                                          <strong> Donn&eacute;es de connexion</strong>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            Ce traitement a pour base juridique notre
                            int&eacute;r&ecirc;t l&eacute;gitime &agrave;
                            fournir et am&eacute;liorer
                            l&rsquo;exp&eacute;rience utilisateur de nos
                            visiteurs et membres de notre site.
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div>
                            Nous collectons vos donn&eacute;es personnelles afin
                            de comprendre quelles sont vos
                            pr&eacute;f&eacute;rences et vous proposer des
                            offres commerciales correspondant &agrave; des
                            services/produits analogues &agrave; ceux
                            d&eacute;j&agrave; command&eacute;s.
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <div>
                              <table>
                                <tbody>
                                  <tr></tr>
                                  <tr>
                                    <td>
                                      <div>
                                        <div>
                                          <strong>
                                            Donn&eacute;es d&rsquo;utilisation
                                            et consommation
                                          </strong>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div>
                                        <div>
                                          <strong>
                                            {" "}
                                            Donn&eacute;es
                                            d&rsquo;identification
                                          </strong>
                                        </div>
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            Ce traitement a pour base juridique notre
                            int&eacute;r&ecirc;t l&eacute;gitime &agrave;
                            fournir et am&eacute;liorer
                            l&rsquo;exp&eacute;rience utilisateur de nos
                            visiteurs et membres de notre site.
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <p>
                Nous vous informons, lors de la collecte de vos donn&eacute;es
                personnelles, si certaines donn&eacute;es doivent &ecirc;tre
                obligatoirement renseign&eacute;es ou si elles sont
                facultatives.
              </p>
            </div>
            <div>
              <p>
                <strong>DESTINATAIRE DES DONNEES A CARACTERE PERSONNEL</strong>
              </p>
            </div>
            <div>
              <p>
                Auront acc&egrave;s &agrave; vos donn&eacute;es personnelles :
              </p>
            </div>
            <div>
              <div>
                <ul>
                  <li>Le personnel de notre soci&eacute;t&eacute; ;</li>
                  <li>
                    Les services charg&eacute;s du contr&ocirc;le (avocat,
                    comptable notamment) ;
                  </li>
                  <li>
                    Nos sous-traitants (prestataires de communication,
                    prestataires de logiciels de productivit&eacute;,
                    prestataire d&rsquo;h&eacute;bergement des donn&eacute;es,
                    prestataires de service de paiement, prestataire de
                    facturation, prestataires d&rsquo;analyse et de mesure
                    d&rsquo;audience, prestataires de gestion de la relation
                    client) ;
                  </li>
                  <li>
                    Peuvent &eacute;galement &ecirc;tre destinataires de vos
                    donn&eacute;es &agrave; caract&egrave;re personnel les
                    organismes publics, exclusivement pour r&eacute;pondre
                    &agrave; nos obligations l&eacute;gales, les auxiliaires de
                    justice, les oﬃciers minist&eacute;riels et les organismes
                    charg&eacute;s d&rsquo;eﬀectuer le recouvrement de
                    cr&eacute;ances.
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <p>
                <strong>
                  DUREE DE CONSERVATION DES DONNEES A CARACTERE PERSONNEL
                </strong>
              </p>
            </div>
            <div>
              <p>
                <em>
                  Concernant les donn&eacute;es relatives &agrave; la gestion de
                  clients et prospects :
                </em>
              </p>
            </div>
            <div>
              <p>
                Vos donn&eacute;es &agrave; caract&egrave;re personnel ne seront
                pas conserv&eacute;es au-del&agrave; de la dur&eacute;e
                strictement n&eacute;cessaire &agrave; la gestion de notre
                relation commerciale avec vous.
              </p>
            </div>
            <div>
              <p>
                Toutefois, les donn&eacute;es permettant d&rsquo;&eacute;tablir
                la preuve d&rsquo;un droit ou d&rsquo;un contrat, devant
                &ecirc;tre conserv&eacute;es au titre du respect d&rsquo;une
                obligation l&eacute;gale, le seront pendant la dur&eacute;e
                pr&eacute;vue par la loi en vigueur.
              </p>
            </div>
            <div>
              <p>
                Concernant d&rsquo;&eacute;ventuelles op&eacute;rations de
                prospection &agrave; destination des clients, leurs
                donn&eacute;es pourront &ecirc;tre conserv&eacute;es pendant un
                d&eacute;lai de trois (3) ans &agrave; compter de la ﬁn de la
                relation commerciale.
              </p>
            </div>
            <div>
              <p>
                Les donn&eacute;es &agrave; caract&egrave;re personnel relatives
                &agrave; un prospect, non client, pourront &ecirc;tre
                conserv&eacute;es pendant un d&eacute;lai de trois (3) ans
                &agrave; compter de leur collecte ou du dernier contact
                &eacute;manant du prospect.&nbsp;
              </p>
            </div>
            <div>
              <p>
                Au terme de ce d&eacute;lai de trois (3) ans, nous pourrons
                reprendre contact avec vous pour savoir si vous souhaitez
                continuer &agrave; recevoir des sollicitations commerciales.
              </p>
            </div>
            <div>
              <p>
                En cas d&rsquo;exercice du droit d&rsquo;acc&egrave;s ou de
                rectiﬁcation, les donn&eacute;es relatives aux pi&egrave;ces
                d&rsquo;identit&eacute; pourront &ecirc;tre conserv&eacute;es
                pendant le d&eacute;lai pr&eacute;vu &agrave; l&rsquo;article 9
                du code de proc&eacute;dure p&eacute;nale, soit un (1) an. En
                cas d&rsquo;exercice du droit d&rsquo;opposition, ces
                donn&eacute;es peuvent &ecirc;tre archiv&eacute;es pendant le
                d&eacute;lai de prescription pr&eacute;vu par l&rsquo;article 8
                du code de proc&eacute;dure p&eacute;nale, soit trois (3) ans.
              </p>
            </div>
            <div>
              <p>
                Les transactions ﬁnanci&egrave;res relatives au paiement des
                achats et des frais via le Site, sont conﬁ&eacute;es &agrave; un
                prestataire de services de paiement qui en assure le bon
                d&eacute;roulement et la s&eacute;curit&eacute;.
              </p>
            </div>
            <div>
              <p>
                Pour les besoins des services, ce prestataire de services de
                paiement peut &ecirc;tre amen&eacute; &agrave; &ecirc;tre
                destinataire de vos donn&eacute;es &agrave; caract&egrave;re
                personnel relatives &agrave; vos num&eacute;ros de cartes
                bancaires, qu&rsquo;il recueille et conserve en notre nom et
                pour notre compte. Nous n&rsquo;avons pas acc&egrave;s &agrave;
                ces donn&eacute;es.
              </p>
            </div>
            <div>
              <p>
                Pour vous permettre de r&eacute;aliser
                r&eacute;guli&egrave;rement des achats ou de r&eacute;gler les
                frais aﬀ&eacute;rents sur le Site, vos donn&eacute;es relatives
                &agrave; vos cartes bancaires sont conserv&eacute;es pendant le
                temps de votre inscription sur le Site et &agrave; tout le
                moins, jusqu&rsquo;au moment o&ugrave; vous r&eacute;alisez
                votre derni&egrave;re transaction.
              </p>
            </div>
            <div>
              <p>
                Les donn&eacute;es relatives au cryptogramme visuel ou CVV2,
                inscrit sur votre carte bancaire, ne sont pas stock&eacute;es.
              </p>
            </div>
            <div>
              <p>
                Si vous refusez que vos donn&eacute;es &agrave; caract&egrave;re
                personnel relatives &agrave; vos num&eacute;ros de cartes
                bancaires soient conserv&eacute;es dans les conditions
                pr&eacute;cis&eacute;es ci-dessus, nous ne conserverons pas ces
                donn&eacute;es au-del&agrave; du temps n&eacute;cessaire pour
                permettre la r&eacute;alisation de la transaction.
              </p>
            </div>
            <div>
              <p>
                En tout &eacute;tat de cause, les donn&eacute;es relatives
                &agrave; celles-ci pourront &ecirc;tre conserv&eacute;es, pour
                une ﬁnalit&eacute; de preuve en cas d&rsquo;&eacute;ventuelle
                contestation de la transaction, en archives
                interm&eacute;diaires, pour la dur&eacute;e pr&eacute;vue par
                l&rsquo;article L 133-24 du Code mon&eacute;taire et ﬁnancier,
                en l&rsquo;occurrence treize (13) mois suivant la date de
                d&eacute;bit. Ce d&eacute;lai peut &ecirc;tre &eacute;tendu
                &agrave; quinze (15) mois aﬁn de prendre en compte la
                possibilit&eacute; d&rsquo;utilisation des cartes de paiement
                &agrave; d&eacute;bit diﬀ&eacute;r&eacute;.
              </p>
            </div>
            <div>
              <p>
                <em>
                  Concernant la gestion des listes d&rsquo;opposition &agrave;
                  recevoir de la prospection :
                </em>
              </p>
            </div>
            <div>
              <p>
                Les informations permettant de prendre en compte votre droit
                d&rsquo;opposition sont conserv&eacute;es au minimum trois (3)
                ans &agrave; compter de l&rsquo;exercice du droit
                d&rsquo;opposition.
              </p>
            </div>
            <div>
              <p>
                <em>
                  Concernant les statistiques de mesure d&rsquo;audience :&nbsp;
                </em>
              </p>
            </div>
            <div>
              <p>
                Les informations stock&eacute;es dans le terminal des
                utilisateurs ou tout autre &eacute;l&eacute;ment utilis&eacute;
                pour identiﬁer les utilisateurs et permettant leur
                tra&ccedil;abilit&eacute; ou fr&eacute;quentation ne seront pas
                conserv&eacute;es au-del&agrave; de treize (13) mois.
              </p>
            </div>
            <div>
              <p>
                <strong>SECURITE</strong>
              </p>
            </div>
            <div>
              <p>
                Nous vous informons prendre toutes pr&eacute;cautions utiles,
                mesures organisationnelles et techniques appropri&eacute;es pour
                pr&eacute;server la s&eacute;curit&eacute;,
                l&rsquo;int&eacute;grit&eacute; et la conﬁdentialit&eacute; de
                vos donn&eacute;es &agrave; caract&egrave;re personnel et
                notamment, emp&ecirc;cher qu&rsquo;elles soient
                d&eacute;form&eacute;es, endommag&eacute;es ou que des tiers non
                autoris&eacute;s y aient acc&egrave;s. Nous recourrons
                &eacute;galement &agrave; des syst&egrave;mes de paiement
                s&eacute;curis&eacute; conformes &agrave; l&rsquo;&eacute;tat de
                l&rsquo;art et &agrave; la r&eacute;glementation applicable.
              </p>
            </div>
            <div>
              <p>
                <strong>HEBERGEMENT</strong>
              </p>
            </div>
            <div>
              <p>
                Nous vous informons que vos donn&eacute;es sont
                conserv&eacute;es et stock&eacute;es, pendant toute la
                dur&eacute;e de leur conservation sur les serveurs de la
                soci&eacute;t&eacute; OVH situ&eacute;s dans l&rsquo;Union
                europ&eacute;enne.
              </p>
            </div>
            <div>
              <p>
                <strong>TRANSFERT HORS DE L&rsquo;UNION EUROPEENNE</strong>
              </p>
            </div>
            <div>
              <p>
                Vos donn&eacute;es peuvent &ecirc;tre transf&eacute;r&eacute;es
                hors de l&rsquo;Union europ&eacute;enne dans le cadre des outils
                que nous utilisons et de nos relations avec nos sous-traitants.
              </p>
            </div>
            <div>
              <p>
                Ce transfert est s&eacute;curis&eacute; au moyen des outils
                suivants :
              </p>
            </div>
            <div>
              <div>
                <ul>
                  <li>
                    Soit ces donn&eacute;es sont transf&eacute;r&eacute;es dans
                    un pays ayant &eacute;t&eacute; jug&eacute; comme oﬀrant un
                    niveau de protection ad&eacute;quat par une d&eacute;cision
                    de la Commission Europ&eacute;enne ;
                  </li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>
                    Soit nous avons conclu avec nos sous-traitants un contrat
                    sp&eacute;ciﬁque encadrant les transferts de vos
                    donn&eacute;es en dehors de l&rsquo;Union Europ&eacute;enne,
                    sur la base des clauses contractuelles types entre un
                    responsable de traitement et un sous-traitant
                    approuv&eacute;es par la Commission Europ&eacute;enne.
                  </li>
                </ul>
              </div>
              <div>
                <p>
                  <strong>
                    ACCES, RECTIFICATION, LIMITATION ET EFFACEMENT DE VOS
                    DONNEES A CARACTERE PERSONNEL
                  </strong>
                </p>
              </div>
            </div>
            <div>
              <div>
                <p>
                  Conform&eacute;ment &agrave; la r&eacute;glementation
                  europ&eacute;enne en mati&egrave;re de protections des
                  donn&eacute;es &agrave; caract&egrave;re personnel, vous
                  b&eacute;n&eacute;ficiez des droits suivants :
                </p>
              </div>
              <div>
                <ul>
                  <li>
                    Un droit d&rsquo;acc&egrave;s vous permettant &agrave; tout
                    moment de savoir si vos donn&eacute;es personnelles sont ou
                    ne sont pas trait&eacute;es par nos services et
                    lorsqu&rsquo;elles le sont, d&rsquo;avoir acc&egrave;s
                    auxdites donn&eacute;es personnelles et aux informations
                    requises par la loi concernant les modalit&eacute;s de
                    traitement de ces donn&eacute;es,&nbsp;
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div>
                <ul>
                  <li>
                    Un droit de rectification vous permettant, de demander
                    &agrave; ce que soient corrig&eacute;es dans les meilleurs
                    d&eacute;lais toute inexactitude concernant les
                    donn&eacute;es personnelles vous concernant,&nbsp;
                  </li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>
                    Un droit &agrave; l&rsquo;effacement, vous permettant de
                    demander dans les meilleurs d&eacute;lais &agrave; ce que
                    vos donn&eacute;es personnelles soient effac&eacute;es, sous
                    r&eacute;serve que cette demande d&rsquo;effacement respecte
                    les conditions requises par la loi applicable,&nbsp;
                  </li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>
                    Un droit &agrave; la limitation du traitement de vos
                    donn&eacute;es personnelles, sous r&eacute;serve que cette
                    demande de limitation respecte les conditions requises par
                    la loi applicable,&nbsp;
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div>
                <ul>
                  <li>
                    Un droit &agrave; la portabilit&eacute; vous permettant de
                    recevoir vos donn&eacute;es personnelles dans un format
                    structur&eacute;, couramment utilis&eacute; et lisible par
                    machine, ou &agrave; demander que ces donn&eacute;es
                    personnelles soit transmises &agrave; un autre responsable
                    de traitement, sous r&eacute;serve que cette demande de
                    portabilit&eacute; respecte les conditions requises par la
                    loi applicable,&nbsp;
                  </li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>
                    Un droit d&rsquo;opposition au traitement de vos
                    donn&eacute;es personnelles pour des raisons tenant &agrave;
                    votre situation particuli&egrave;re sous r&eacute;serve que
                    cette opposition respecte les conditions requises par la loi
                    applicable,
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div>
                <ul>
                  <li>
                    Le droit de retirer &agrave; tout moment le consentement
                    sp&eacute;cifique donn&eacute; &agrave; la collecte de vos
                    donn&eacute;es personnelles, notamment lorsque le traitement
                    de vos donn&eacute;es personnelles sert &agrave; des fins de
                    prospection commerciale,
                  </li>
                </ul>
              </div>
              <div>
                <ul>
                  <li>
                    Le droit de d&eacute;finir des directives relatives &agrave;
                    la conservation, &agrave; l&apos;effacement et &agrave; la
                    communication de vos donn&eacute;es &agrave;
                    caract&egrave;re personnel &agrave; compter de votre
                    d&eacute;c&egrave;s.
                  </li>
                </ul>
              </div>
              <div>
                <p>
                  Vous avez le droit d&rsquo;obtenir la limitation du traitement
                  de vos donn&eacute;es personnelles, dans les cas d&eacute;ﬁnis
                  &agrave; l&rsquo;article 18 du RGPD :
                </p>
              </div>
            </div>
            <div>
              <div>
                <ul>
                  <li>
                    Pendant la dur&eacute;e de v&eacute;riﬁcation que nous
                    mettons en &oelig;uvre, lorsque vous contestez
                    l&rsquo;exactitude de vos donn&eacute;es &agrave;
                    caract&egrave;re personnel,
                  </li>
                  <li>
                    Lorsque le traitement de ces donn&eacute;es est illicite, et
                    que vous souhaitez limiter ce traitement plut&ocirc;t que
                    supprimer vos donn&eacute;es,
                  </li>
                  <li>
                    Lorsque nous n&rsquo;avons plus besoin de vos donn&eacute;es
                    personnelles, mais que vous souhaitez leur conservation pour
                    exercer vos droits,
                  </li>
                  <li>
                    Pendant la p&eacute;riode de v&eacute;riﬁcation des motifs
                    l&eacute;gitimes, lorsque vous vous &ecirc;tes
                    oppos&eacute;s au traitement de vos donn&eacute;es
                    personnelles.
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <p>
                Il est rappel&eacute; aux personnes dont les donn&eacute;es sont
                collect&eacute;es sur le fondement de notre int&eacute;r&ecirc;t
                l&eacute;gitime, qu&rsquo;elles peuvent &agrave; tout moment
                s&apos;opposer au traitement des donn&eacute;es les concernant. Nous
                pouvons toutefois &ecirc;tre amen&eacute;s &agrave; poursuivre
                le traitement s&rsquo;il existe des motifs l&eacute;gitimes pour
                le traitement qui pr&eacute;valent sur vos droits et
                libert&eacute;s ou si le traitement est n&eacute;cessaire pour
                constater, exercer ou d&eacute;fendre nos droits en justice.
              </p>
            </div>
            <div>
              <p>
                Vous pouvez vous d&eacute;sabonner de nos emails promotionnels
                via le lien fourni dans les emails. M&ecirc;me si vous
                choisissez de ne plus recevoir de messages promotionnels de
                notre part, vous continuerez &agrave; recevoir nos messages
                administratifs.
              </p>
            </div>
            <div>
              <p>
                <strong>
                  PORTABILITE DE VOS DONNEES A CARACTERE PERSONNEL
                </strong>
              </p>
            </div>
            <div>
              <p>
                Vous disposez d&rsquo;un droit &agrave; la portabilit&eacute;
                des donn&eacute;es &agrave; caract&egrave;re personnel que vous
                nous aurez fournies, entendues comme les donn&eacute;es que vous
                avez d&eacute;clar&eacute;es activement et consciemment dans le
                cadre de l&rsquo;acc&egrave;s et de l&rsquo;utilisation des
                services, ainsi que des donn&eacute;es
                g&eacute;n&eacute;r&eacute;es par votre activit&eacute; dans le
                cadre de l&rsquo;utilisation des services.&nbsp;
              </p>
            </div>
            <div>
              <p>
                Nous vous rappelons que ce droit ne porte pas sur les
                donn&eacute;es collect&eacute;es et trait&eacute;es sur une
                autre base l&eacute;gale que le consentement ou
                l&rsquo;ex&eacute;cution du contrat nous liant.
              </p>
            </div>
            <div>
              <p>
                Ce droit peut &ecirc;tre exerc&eacute; gratuitement, &agrave;
                tout moment, et notamment lors de la fermeture de votre compte
                sur le Site, aﬁn de r&eacute;cup&eacute;rer et de conserver vos
                donn&eacute;es &agrave; caract&egrave;re personnel.
              </p>
            </div>
            <div>
              <p>
                Dans ce cadre, nous vous adresserons vos donn&eacute;es &agrave;
                caract&egrave;re personnel, par tous moyens jug&eacute;s utiles,
                dans un format ouvert standard couramment utilis&eacute; et
                lisible par informatique, conform&eacute;ment &agrave;
                l&rsquo;&eacute;tat de l&rsquo;art.
              </p>
            </div>
            <div>
              <p>
                <strong>
                  INTRODUCTION D&rsquo;UNE RECLAMATION DEVANT UNE AUTORITE DE
                  CONTROLE
                </strong>
              </p>
            </div>
            <div>
              <p>
                Vous &ecirc;tes &eacute;galement inform&eacute;s que vous
                disposez du droit d&rsquo;introduire une r&eacute;clamation
                aupr&egrave;s d&rsquo;une autorit&eacute; de contr&ocirc;le
                comp&eacute;tente, (la Commission Nationale Informatique et
                Libert&eacute;s pour la France), dans l&rsquo;Etat membre dans
                lequel se trouve votre r&eacute;sidence habituelle, votre lieu
                de travail ou le lieu o&ugrave; la violation de vos droits
                aurait &eacute;t&eacute; commise, si vous consid&eacute;rez que
                le traitement de vos donn&eacute;es &agrave; caract&egrave;re
                personnel objet de la pr&eacute;sente charte constitue une
                violation des textes applicables.
              </p>
            </div>
            <div>
              <p>
                Ce recours pourra &ecirc;tre exerc&eacute; sans pr&eacute;judice
                de tout autre recours devant une juridiction administrative ou
                juridictionnelle. En eﬀet, vous disposez &eacute;galement
                d&rsquo;un droit &agrave; un recours administratif ou
                juridictionnel eﬀectif si vous consid&eacute;rez que traitement
                de vos donn&eacute;es &agrave; caract&egrave;re personnel objet
                de la pr&eacute;sente charte constitue une violation des textes
                applicables.
              </p>
            </div>
            <div>
              <p>
                <strong>MODIFICATIONS</strong>
              </p>
            </div>
            <div>
              <p>
                Nous nous r&eacute;servons le droit, &agrave; notre seule
                discr&eacute;tion, de modiﬁer &agrave; tout moment la
                pr&eacute;sente Charte en totalit&eacute; ou en partie.&nbsp;
              </p>
            </div>
            <div>
              <p>
                Ces modifications entreront en vigueur &agrave; compter de la
                publication de la nouvelle charte.&nbsp;
              </p>
            </div>
          </Grid>
        </Grid>
      </PageWrapper>
    </div>
  );
}
