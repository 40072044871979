import React from 'react';
import Grid from './grid';

export default function Partners() {
  return (
    <Grid container maxWidth='lg' className='partners'>
      <div className='warpper'>
        <img className='man' src='/images/p-man.png' alt='p-man' />
        <div className='top'>
          <h3>Financer votre projet auto facilement</h3>
        </div>
        <div className='logos'>
          <img src='/images/logo.png' alt='click_and_fi' />
          <span>Avec notre partenaire</span>
          <img src='/images/cetelem-logo.png' alt='cetelem' />
        </div>
      </div>
    </Grid>
  );
}
