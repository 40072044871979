import React from 'react';
import PageWrapper from '../components/PageWrapper';
import Head from '../components/head';
import BlockImage from '../components/blockImage';
import Button from '../components/button';
import FormProlong from '../components/formProlong';
import { Helmet } from 'react-helmet';
import Items from '../components/items';

export default function ProlongPage() {
  return (
    <div className='page-renew'>
      <Helmet>
        <title>Prolonger - Click & Fi</title>
        <meta
          name='description'
          content='Prolongez votre contrat de location et conservez votre mobilité quotidienne avec Click & Fi. Nous vous accompagnons pour préserver vos garanties et optimiser vos coûts.'
        />
        <link rel='canonical' href='https://www.click-and-fi.fr/prolonger' />
      </Helmet>
      <PageWrapper>
        <Head
          promo
          anchorContent='Prolongez'
          anchorStyle={{ backgroundColor: '#b9764f' }}
        >
          <>
            <span className='bottom'>
              <b>
                FAITES DES ÉCONOMIES EN PROLONGEANT VOTRE LOCATION AUTOMOBILE
              </b>
            </span>
          </>
        </Head>
        <BlockImage>
          <div className='content'>
            <div className='block-head'>
              <h3>
                <b>
                  <span className='bottom'>
                    <span className='highlight'>PROLONGER</span>
                    <br /> AVEC CLICK & FI
                  </span>
                </b>
              </h3>
            </div>
            <div className='block-body'>
              <p>
                <b>
                  Prolonger sa location avec CLICK & FI …{' '}
                  <span className='new-line'>La solution pour :</span>
                </b>
              </p>
              <br />
              <ul>
                <li>Baisser son loyer véhicule mensuel</li>
                <li>Préserver les prestations (entretien garantie)</li>
                <li>Adapter son kilométrage</li>
                <li>Conserver son véhicule</li>
              </ul>
              <Button text='Je souhaite être accompagné' to='#form-prolonger' />
              <div className='flowed'>
                <div className='content'>
                  <div className='block-head'>
                    <h3>
                      <b>Comment ça marche ?</b>
                    </h3>
                  </div>
                  <div className='block-body'>
                    <p>
                      Vous arrivez à la fin de votre contrat de Location et
                      allez devoir prendre une décision ….Racheter ? Restituer
                      au concessionnaire ? ou Renouveler votre voiture ?
                    </p>
                    <br />
                    <p>
                      Mais il y a de très fortes chances que vous soyez
                      satisfait de votre véhicule actuellement loué ou que vous
                      souhaitiez vous donner plus de temps pour choisir une
                      nouvelle voiture…
                    </p>
                    <br />
                    <p>Et si Click & Fi vous proposiez une 4ème option ?</p>
                    <br />
                    <p>
                      <b>
                        Prolonger votre LOA en cours et donnez vous le temps !
                      </b>
                    </p>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img src='/images/blockimage.jpeg' alt='block-image' />
        </BlockImage>
        <Items />
        <BlockImage reverse>
          <div className='content content-two'>
            <div className='block-head'>
              <h3>
                <span className='top'>
                  MON NOUVEAU <span className='highlight'>BUDGET MENSUEL</span>{' '}
                </span>
                <span className='bottom'>
                  EN <span className='highlight'>4 ETAPES</span>
                </span>
              </h3>
            </div>
            <div className='block-body'>
              <p>
                <b>1</b> - Définition du prix de vente : Il sera calculé sur la
                base du solde de votre location en cours + les Frais de mise à
                disposition (nouvelle carte grise et frais de démarches
                administratives)
              </p>
              <br />
              <p>
                <b>2</b> - Définition du nouveau couple Durée de location /
                Kilométrage annuel
              </p>
              <br />
              <p>
                <b>3</b> - Définition de la nouvelle valeur future minimum
                garantie sur les bases du marché actuel des véhicules d’occasion
                & de l’état standard de votre véhicule à aujourd’hui
              </p>
              <br />
              <p>
                <b>4</b> - Définition des nouvelles prestations souhaitées
                (Contrat d’entretien / Prolongation de garantie / Assurance à la
                personne)
              </p>
            </div>
          </div>
          <img src='/images/blockimage2.jpeg' alt='block-image' />
        </BlockImage>
        <FormProlong />
      </PageWrapper>
    </div>
  );
}
