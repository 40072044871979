import React, { useEffect, useState } from "react";
import Grid from "./grid";
import { NavLink, Link } from "react-router-dom";
import Button from "../components/button";
import Icon from "../components/Icon";
import { getPartner } from "../shared/Partner";
const Footer = () => {
  const currentYear = new Date().getFullYear();
  const [user, setUser] = useState<null | { color: string }>(null);

  useEffect(() => {
    getPartner().then((partner) => {
      if (partner && partner.code) {
        setUser(partner);
      }
    });
  }, []);
  return (
    <footer>
      <Grid container spacing={4}>
        <Grid xs={12} md={8}>
          <Grid container maxWidth="lg" className="footer-top" spacing={6}>
            <Grid xs={12} md={7}>
              <p className="styled-text">
                AVEC CLICK AND FI
                <span className="highlight"> VOUS ALLEZ ADORER</span>
              </p>
            </Grid>
            <Grid xs={12} md={5} sx={{ p: 0, ml: "auto" }}>
              <div className="btns-wrapper">
                <Button
                  text="Prolongez"
                  dark
                  to={
                    user ? "/espace-partenaire?target=prolonger" : "/prolonger"
                  }
                />
                <Button
                  text="Renouvelez"
                  to={
                    user
                      ? "/espace-partenaire?target=renouveler"
                      : "/renouveler"
                  }
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} md={4} sx={{ my: 0 }}>
          <div className="footer-right">
            <div className="content">
              <div className="member">
                <p>Membre de</p>
                <img src="./images/member.png" alt="image" />
              </div>
              <div className="number">
                <p>
                  Numéro Orias : <span className="new-line">20008379</span>
                </p>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>

      <div className="footer-bottom">
        <Grid container className="container" maxWidth="lg" spacing={2}>
          <Grid xs={12} md={9} className="nav-list">
            <ul>
              <li>
                <NavLink to="/">Accueil</NavLink>
              </li>
              <li>
                <NavLink to="/comment-ca-marche">Comment ça marche ?</NavLink>
              </li>
              <li>
                <NavLink to="/qui-sommes-nous">Qui sommes nous ?</NavLink>
              </li>
              {!user && (
                <>
                  <li>
                    <NavLink to="/renouveler">Renouveler</NavLink>
                  </li>
                  <li>
                    <NavLink to="/prolonger">Prolonger</NavLink>
                  </li>
                </>
              )}
              <li>
                <NavLink to="/connexion">Espace partenaire</NavLink>
              </li>
            </ul>
          </Grid>
          <Grid xs={12} md={3} className="nav-social">
            <ul>
              <li>
                <a rel="noreferrer" href="https://facebook.com" target="_blank">
                  <Icon icon="Facebook" size="24" />
                </a>
              </li>
              <li>
                <a rel="noreferrer" href="https://twitter.com" target="_blank">
                  <Icon icon="Twitter" size="24" />
                </a>
              </li>
            </ul>
          </Grid>
          <Grid xs={12} className="footer-privacy">
            <Grid
              container
              sx={{
                flexDirection: {
                  xs: "column-reverse",
                  md: "row",
                },
              }}
            >
              <Grid xs={12} md={4}>
                <p className="cr">
                  &copy; {currentYear} Click & Fi - All rights reserved.
                </p>
              </Grid>
              <Grid xs={12} md={4} className="footer-logo-dk">
                <Link to="/">
                  <img src="./images/logo-white.png" alt="Logo" />
                </Link>
              </Grid>
              <Grid xs={12} md={4}>
                <ul>
                  <li>
                    <Link to="/mentions-legales">Mentions Légales</Link>
                  </li>
                  <li>
                    <Link to="/politique-de-confidentialite">
                      Politique de confidentialité
                    </Link>
                  </li>
                  <li>
                    <Link to="/conditions-generales-utilisation">
                      Conditions générales d&apos;utilisation
                    </Link>
                  </li>
                  {/*
                  <li>
                    <Link to="/conditions-generales-de-vente">
                      Conditions générales de vente
                    </Link>
                  </li>
                  */}
                  <li>
                    <Link to="/politique-de-cookies">
                      Politique des cookies
                    </Link>
                  </li>
                </ul>
              </Grid>
              <Grid xs={12} md={4} className="footer-logo-mb">
                <Link to="/">
                  <img src="./images/logo-white.png" alt="Logo" />
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </footer>
  );
};
export default Footer;
