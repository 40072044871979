import React, { useEffect } from 'react';
import Grid from './grid';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as yup from 'yup';
import { radioUncheck, setUTMValues, isDev } from '../shared/helpers';
import { getPartner } from '../shared/Partner';

import { yupResolver } from '@hookform/resolvers/yup';
import Icon from './Icon';

// fields name
const nameDate = isDev ? '00N7a00000JWOoH' : '00N68000004KdzY';
const nameHoure = isDev ? '00N7a00000KWxCs' : '00N68000004KpAa';
const nameCodePartner = isDev ? '00N7a00000KXVxy' : '00N68000004KpA6';
const nameAandT = '00N7a00000KxuJz';

const schema = yup.object().shape({
  first_name: yup.string().max(40).required('Ce champ est obligatoire'),
  last_name: yup.string().max(80).required('Ce champ est obligatoire'),
  phone: yup.number().typeError('doit être un nombre').required(),
  email: yup
    .string()
    .email("S'il vous plaît, mettez une adresse email valide")
    .max(80)
    .required('Ce champ est obligatoire'),
  [nameDate]: yup.string().required('Ce champ est obligatoire'), // Date
  [nameHoure]: yup.string().required('Ce champ est obligatoire'), // Houre
  [nameCodePartner]: yup.string(), //Code Partner
  [nameAandT]: yup
    .string()
    .oneOf(['1'], 'Vous devez accepter les Termes')
    .required('Vous devez accepter les Termes'),
});

const houres = [
  { value: '8h-10h', label: '8h-10h' },
  { value: '10h-12h', label: '10h-12h' },
  { value: '12h-14h', label: '12h-14h' },
  { value: '14h-16h', label: '14h-16h' },
  { value: '16h-18h', label: '16h-18h' },
  { value: '18h-20h', label: '18h-20h' },
];

export default function SmallFormProlong({
  spaceMember,
}: {
  spaceMember?: boolean;
}) {
  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    const asyncPartner = async () => {
      const partner = await getPartner();
      const leadSourceInput: any = document.getElementsByName('lead_source')[0];
      if (partner && partner.code) {
        const partnerInput: any =
          document.getElementsByName(nameCodePartner)[0];
        partnerInput.value = partner.code;

        leadSourceInput.value = 'Apport_affaire';
      } else {
        leadSourceInput.value = 'Formulaire_site_web';
      }
    };
    asyncPartner();
    setUTMValues();
  }, []);

  const onSubmit = (data: any, e: any) => {
    setTimeout(() => {
      e.target.submit();
    }, 0);
  };

  useEffect(() => {
    // radio uncheck
    radioUncheck(getValues, setValue);
  }, [watch]);

  return (
    <div className='' id='form'>
      <form
        autoComplete='off'
        className='form'
        id='form-prolonger'
        action={`https://${
          isDev ? 'test' : 'webto'
        }.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8`}
        method='POST'
        onSubmit={handleSubmit(onSubmit)}
      >
        <input
          type='hidden'
          name='recordType'
          id='recordType'
          value={isDev ? '0127a000002pH7l' : '01268000000HkFa'}
        />
        <input
          type='hidden'
          name='oid'
          value={isDev ? '00D7a00000055rv' : '00D68000002836s'}
        />
        <input
          type='hidden'
          name='retURL'
          value={`${
            isDev
              ? 'https://click-and-fi.lagentx.tech'
              : 'https://click-and-fi.fr'
          }/?statut=succes&form=prolonger`}
        />
        {/* code partner*/}
        <input type='hidden' id={nameCodePartner} name={nameCodePartner} />

        {/* UTM */}
        <input id='utm_source' name='00N68000004KpCM' type='hidden' />
        <input id='utm_medium' name='00N68000004KpCR' type='hidden' />
        <input id='utm_term' name='00N68000004KpCW' type='hidden' />
        <input id='utm_campaign' name='00N68000004KpCb' type='hidden' />
        <input id='utm_content' name='00N68000004KpCg' type='hidden' />
        <input id='lead_source' name='lead_source' type='hidden' />

        <Grid container maxWidth='lg' className='form-content' spacing={0}>
          <Grid
            xs={12}
            mdOffset={0}
            sx={{
              my: 0,
            }}
          >
            <Grid
              container
              maxWidth='lg'
              className='form-controllers'
              spacing={0}
            >
              <Grid xs={12} md={5.5} mdOffset={0}>
                <label className='form-label' htmlFor='first_name'>
                  Prénom*
                </label>
                <input
                  className='form-control'
                  placeholder='Jean'
                  {...register('first_name')}
                />

                {errors['first_name']?.message && (
                  <p className='error'>
                    {errors['first_name'] &&
                      (errors['first_name'] as { message: string }).message}
                  </p>
                )}
              </Grid>
              <Grid xs={12} md={5.5} mdOffset={1}>
                <label className='form-label' htmlFor='last_name'>
                  Nom*
                </label>
                <input
                  className='form-control'
                  placeholder='Dupont'
                  {...register('last_name')}
                />
                {errors['last_name']?.message && (
                  <p className='error'>
                    {errors['last_name'] &&
                      (errors['last_name'] as { message: string }).message}
                  </p>
                )}
              </Grid>
              <Grid xs={12} md={5.5} mdOffset={0}>
                <label className='form-label' htmlFor='phone'>
                  Téléphone*
                </label>
                <input
                  className='form-control'
                  placeholder='+33'
                  {...register('phone')}
                />
                {errors['phone']?.message && (
                  <p className='error'>
                    {errors['phone'] &&
                      (errors['phone'] as { message: string }).message}
                  </p>
                )}
              </Grid>
              <Grid xs={12} md={5.5} mdOffset={1}>
                <label className='form-label' htmlFor='email'>
                  Email*
                </label>
                <input
                  className='form-control'
                  placeholder='contact@gmail.com'
                  {...register('email')}
                />
                {errors['email']?.message && (
                  <p className='error'>
                    {errors['email'] &&
                      (errors['email'] as { message: string }).message}
                  </p>
                )}
              </Grid>
              <Grid xs={12} md={5.5} mdOffset={0}>
                <label className='form-label' htmlFor='date'>
                  Date souhaitée*
                </label>
                <Controller
                  control={control}
                  name={nameDate}
                  render={({ field }) => (
                    <DatePicker
                      dateFormat='dd/MM/yyyy'
                      {...register(nameDate)}
                      placeholderText='00/00/2023'
                      className='form-control'
                      onChange={(date: any) => field.onChange(date)}
                      selected={field.value}
                      minDate={new Date()}
                    />
                  )}
                />
                {errors[nameDate]?.message && (
                  <p className='error'>
                    {errors[nameDate] &&
                      (errors[nameDate] as { message: string }).message}
                  </p>
                )}
              </Grid>
              <Grid xs={12} md={5.5} mdOffset={1}>
                <label className='form-label' htmlFor='status'>
                  Tranche horaire*
                </label>
                <Controller
                  control={control}
                  rules={{
                    required: true,
                  }}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Select
                      {...register(nameHoure)}
                      className='form-control form-control-select'
                      options={houres}
                      onBlur={onBlur}
                      onChange={(data: any) => {
                        onChange(data.value);
                      }}
                      value={value ? { value, label: value } : null}
                      placeholder='00h-00h'
                    />
                  )}
                  name={nameHoure}
                />

                {errors[nameHoure]?.message && (
                  <p className='error'>
                    {errors[nameHoure] &&
                      (errors[nameHoure] as { message: string }).message}
                  </p>
                )}
              </Grid>
              <Grid
                xs={12}
                md={12}
                className='form-control-wrapper form-control-wrapper-checkbox'
              >
                <input
                  type='checkbox'
                  className='form-control red-term'
                  value='1'
                  id={nameAandT}
                  {...register(nameAandT)}
                />
                <label htmlFor={nameAandT} className='tandc'>
                  Je reconnais que les champs marqués d’un astérisque sont
                  obligatoires. Click & FI traite les données recueillies pour
                  répondre à votre demande de renseignement. Pour en savoir plus
                  sur la gestion de vos données personnelles et pour exercer vos
                  droits, reportez-vous à la politique de confidentialité.
                </label>
                {errors[nameAandT]?.message && (
                  <p className='error'>
                    {errors[nameAandT] &&
                      (errors[nameAandT] as { message: string }).message}
                  </p>
                )}
              </Grid>
            </Grid>

            {/*  */}
            <button type='submit' className='btn-submit-pro'>
              envoyer
              <div className='icon'>
                <Icon icon='ArrowRight' size={15} />
              </div>
            </button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
}
