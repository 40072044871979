import React, {ReactNode} from 'react';
import Grid from './grid';
import Icon from './Icon';

interface Props {
  children: ReactNode;
  color?: string;
  bgColor?: string;
  img?: string;
  logo?: string;
  anchorContent?: string;
  anchorStyle?: React.CSSProperties;
  promo?: boolean;
}

export default function Head({
  children,
  color,
  bgColor,
  logo,
  anchorContent,
  anchorStyle,
  promo,
}: Props) {
  return (
    <div
      className={`head${color ? ' head-green' : ''}`}
      style={{
        backgroundColor: bgColor ? bgColor : 'inherit',
        backgroundImage: bgColor ? 'none' : undefined,
      }}>
      {promo && (
        <div className="promo">
          <div className="bg"></div>
          <div className="promo-content">
            <p>
              Jusqu’à
              <span>-30</span>
              sur vos loyers *
            </p>
          </div>
          <p className="info">
            (*) la baisse du montant des loyers suppose un allongement de la
            durée de votre contrat de location en cours et votre nouveau
            kilométrage défini ensemble.
          </p>
        </div>
      )}
      <Grid container maxWidth="lg">
        <div className="wrapper">
          <h1>{children}</h1>
          {anchorContent && (
            <a href="#form" style={anchorStyle}>
              {anchorContent}
              <div className="icon-wrapper">
                <Icon icon="ArrowRight" size={15} />
              </div>
            </a>
          )}
          {logo && <img className="logo" src={logo} alt="" />}
        </div>
      </Grid>
    </div>
  );
}
