import { Partner } from "../shared/APIs";
const getPartner = async (sendReq?: boolean) => {
  const partner = await localStorage.getItem("user");
  if (partner) {
    let partnerParse = JSON.parse(partner);
    if (partnerParse && partnerParse.code) {
      if (sendReq) {
        await Partner(partnerParse.code)
          .then(({ data }) => {
            partnerParse = data;
          })
          .catch((err) => {
            console.error(err);
            partnerParse = false;
          });
      } else {
        return partnerParse;
      }
    } else {
      partnerParse = false;
    }
    return partnerParse;
  } else {
    return false;
  }
};

const setPartner = (data: any): void => {
  localStorage.setItem("user", JSON.stringify(data));
};

const removePartner = (): void => {
  localStorage.removeItem("user");
};

export { getPartner, setPartner, removePartner };
