import React from 'react';
import PageWrapper from '../components/PageWrapper';
import Head from '../components/head';
import Grid from '../components/grid';
import { Helmet } from 'react-helmet';

export default function ConditionsGeneralUtilisation() {
  return (
    <div className='page-legal'>
      <Helmet>
        <title>Conditions générales d&apos;utilisation - Click & Fi</title>
        <meta
          name='description'
          content="En savoir plus sur nos conditions générales d'utilisation et nos engagements."
        />
        <link
          rel='canonical'
          href='https://www.click-and-fi.fr/conditions-generales-utilisation'
        />
      </Helmet>
      <PageWrapper>
        <Head>
          <b>CONDITIONS GÉNÉRALES D’UTILISATION</b>
        </Head>
        <Grid container spacing={4} maxWidth='lg'>
          <Grid sm={12} className='text-privacy'>
            <div>
              <p>
                <strong>1 - OBJET</strong>
              </p>
            </div>
            <div>
              <p>
                Les pr&eacute;sentes conditions g&eacute;n&eacute;rales
                d&rsquo;utilisation ont pour objet de d&eacute;finir les
                modalit&eacute;s et conditions d&rsquo;utilisation des services
                propos&eacute;s sur le site (ci-apr&egrave;s : les &laquo;
                <strong>Services </strong>&raquo;), ainsi que de d&eacute;finir
                les droits et obligations des parties dans ce cadre.&nbsp;
              </p>
            </div>
            <div>
              <p>
                Elles sont notamment accessibles et imprimables &agrave; tout
                moment par un lien direct en bas de la page d&rsquo;accueil du
                site.
              </p>
            </div>
            <div>
              <p>
                Elles peuvent &ecirc;tre compl&eacute;t&eacute;es, le cas
                &eacute;ch&eacute;ant, par des conditions d&rsquo;utilisation
                particuli&egrave;res &agrave; certains Services. En cas de
                contradiction, les conditions particuli&egrave;res
                pr&eacute;valent sur ces conditions g&eacute;n&eacute;rales.
              </p>
            </div>
            <div>
              <p>
                L&apos;inscription de vos coordonn&eacute;es sur ce site,
                notamment sur nos formulaires de contact, ne constitue en aucun
                cas un contrat de location avec option d&apos;achat.&nbsp;
              </p>
            </div>
            <div>
              <p>
                Les services mentionn&eacute;s sur le site ainsi que les
                &eacute;ventuelles simulations n&rsquo;ont pas de valeur
                contractuelle et ne constituent pas une offre contractuelle. Ces
                informations sont susceptibles d&rsquo;&ecirc;tre
                modifi&eacute;es &agrave; tout moment. L&rsquo;inscription de
                vos coordonn&eacute;es sur ce site ne constitue en aucun cas un
                engagement contractuel.
              </p>
            </div>
            <div>
              <p>
                Une offre pr&eacute;alable de financement pourra vous &ecirc;tre
                adress&eacute;e sur demande, apr&egrave;s entretien avec notre
                service commercial, et, en cas d&rsquo;acceptation de votre
                part, constituera un contrat de LOA sous r&eacute;serve
                d&rsquo;acceptation
              </p>
            </div>
            <div>
              <p>
                <strong>2 &ndash; EXPLOITANT DES SERVICES</strong>
              </p>
            </div>
            <div>
              <p>
                Les Services sont exploit&eacute;s par la soci&eacute;t&eacute;
                SIMPLY-FY, soci&eacute;t&eacute; par actions simplifi&eacute;e
                au capital social de 54.400 euros, immatricul&eacute;e au RCS de
                Dijon sous le n&deg;890 392 509, dont le si&egrave;ge social est
                situ&eacute; 8F, rue Jeanne Barret &ndash; 21000 Dijon
                (ci-apr&egrave;s : &laquo; <strong>Simply-Fy </strong>&raquo;).
              </p>
            </div>
            <div>
              <p>
                Simply-Fy peut &ecirc;tre contact&eacute;e aux
                coordonn&eacute;es suivantes :
              </p>
            </div>
            <div>
              <p>Adresse postale : 8F, rue Jeanne Barret &ndash; 21000 Dijon</p>
            </div>
            <div>
              <p>
                Adresse &eacute;lectronique :{' '}
                <a href='mailto:contact@simply-fy.com'>contact@simply-fy.com</a>
              </p>
            </div>
            <div>
              <div>
                <p>
                  <strong>3 &ndash; ACCES AU SITE ET AUX SERVICES</strong>
                </p>
              </div>
              <div>
                <p>
                  Les Services sont accessibles, sous r&eacute;serve des
                  restrictions pr&eacute;vues sur le site :
                </p>
              </div>
              <div>
                <ul>
                  <li>
                    &agrave; toute personne physique disposant de la pleine
                    capacit&eacute; juridique pour s&rsquo;engager au titre des
                    pr&eacute;sentes conditions g&eacute;n&eacute;rales. La
                    personne physique qui ne dispose pas de la pleine
                    capacit&eacute; juridique ne peut acc&eacute;der au Site et
                    aux Services qu&rsquo;avec l&rsquo;accord de son
                    repr&eacute;sentant l&eacute;gal ;
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div>
                <ul>
                  <li>
                    &agrave; toute personne morale agissant par
                    l&rsquo;interm&eacute;diaire d&rsquo;une personne physique
                    disposant de la capacit&eacute; juridique pour contracter au
                    nom et pour le compte de la personne morale.
                  </li>
                </ul>
              </div>
              <div>
                <p>
                  <strong>
                    4 &ndash; ACCEPTATION DES CONDITIONS GENERALES
                  </strong>
                </p>
              </div>
              <div>
                <p>
                  L&rsquo;acceptation des pr&eacute;sentes conditions
                  g&eacute;n&eacute;rales est mat&eacute;rialis&eacute;e par une
                  case &agrave; cocher dans le formulaire d&rsquo;inscription
                  et/ou de contact. Cette acceptation ne peut &ecirc;tre que
                  pleine et enti&egrave;re. Toute adh&eacute;sion sous
                  r&eacute;serve est consid&eacute;r&eacute;e comme nulle et non
                  avenue. L&rsquo;Utilisateur qui n&rsquo;accepte pas
                  d&rsquo;&ecirc;tre li&eacute; par les pr&eacute;sentes
                  conditions g&eacute;n&eacute;rales ne doit pas utiliser les
                  Services.
                </p>
              </div>
            </div>
            <div>
              <p>
                <strong>5 &ndash; INSCRIPTION SUR LE SITE</strong>
              </p>
            </div>
            <div>
              <p>
                L&rsquo;utilisation des Services ne n&eacute;cessite pas que
                l&rsquo;Utilisateur s&rsquo;inscrive sur le site.
              </p>
            </div>
            <div>
              <p>
                <strong>6 &ndash; DESCRIPTION DES SERVICES</strong>
              </p>
            </div>
            <div>
              <p>
                L&rsquo;Utilisateur a acc&egrave;s aux Services d&eacute;crits
                sur le site, sous une forme et selon les fonctionnalit&eacute;s
                et moyens techniques que Simply-Fy juge les plus
                appropri&eacute;s.
              </p>
            </div>
            <div>
              <p>
                <strong>7 &ndash; SERVICES PAY6ANTS</strong>
              </p>
            </div>
            <div>
              <p>Le prix des Services est indiqu&eacute; sur le site.</p>
            </div>
            <div>
              <p>
                Sauf mention contraire, ils sont exprim&eacute;s en euros et
                toutes taxes fran&ccedil;aises comprises.
              </p>
            </div>
            <div>
              <p>
                Simply-Fy se r&eacute;serve le droit, &agrave; sa libre
                discr&eacute;tion et selon des modalit&eacute;s dont elle sera
                seule juge, de proposer des offres promotionnelles ou
                r&eacute;ductions de prix.
              </p>
            </div>
            <div>
              <p>
                Le prix de Services peut faire l&rsquo;objet d&rsquo;une
                r&eacute;vision par Simply-Fy &agrave; tout moment, &agrave; sa
                libre discr&eacute;tion.
              </p>
            </div>
            <div>
              <p>
                L&rsquo;Utilisateur sera inform&eacute; de ces modifications par
                Simply-Fy par email sous un pr&eacute;avis de trois (3) mois au
                moins avant l&rsquo;entr&eacute;e en vigueur des nouveaux
                tarifs.
              </p>
            </div>
            <div>
              <p>
                L&rsquo;Utilisateur qui n&rsquo;accepte pas les nouveaux prix
                doit mettre fin &agrave; son utilisation des Services selon les
                modalit&eacute;s pr&eacute;vues &agrave; l&rsquo;article 18. A
                d&eacute;faut, il sera r&eacute;put&eacute; avoir accept&eacute;
                les nouveaux tarifs.
              </p>
            </div>
            <div>
              <p>
                Les Services font l&rsquo;objet de factures qui sont
                communiqu&eacute;es &agrave; l&rsquo;Utilisateur par tout moyen
                utile.
              </p>
            </div>
            <div>
              <p>
                Les modalit&eacute;s de paiement du prix des Services sont
                d&eacute;crites sur le site.
              </p>
            </div>
            <div>
              <div>
                <p>
                  <strong>8 &ndash; DONNEES</strong>
                </p>
              </div>
              <div>
                <p>
                  L&rsquo;Utilisateur reconna&icirc;t et accepte
                  express&eacute;ment :
                </p>
              </div>
              <div>
                <ol>
                  <li>
                    que les donn&eacute;es recueillies sur le site et sur les
                    &eacute;quipements informatiques de Simply-Fyfont foi de la
                    r&eacute;alit&eacute; des op&eacute;rations intervenues dans
                    le cadre des pr&eacute;sentes ;
                  </li>
                </ol>
              </div>
            </div>
            <div>
              <div>
                <ol>
                  <li>
                    que ces donn&eacute;es constituent le seul mode de preuve
                    admis entre les parties, notamment pour le calcul des sommes
                    dues &agrave; Simply-Fy.
                  </li>
                </ol>
              </div>
              <div>
                <p>
                  L&rsquo;Utilisateur peut acc&eacute;der &agrave; ces
                  donn&eacute;es dans son Espace Personnel.
                </p>
              </div>
            </div>
            <div>
              <p>
                <strong>9 &ndash; OBLIGATIONS DE L&rsquo;UTILISATEUR</strong>
              </p>
            </div>
            <div>
              <p>
                Sans pr&eacute;judice des autres obligations pr&eacute;vues aux
                pr&eacute;sentes, l&rsquo;Utilisateur s&rsquo;engage &agrave;
                respecter les obligations qui suivent :
              </p>
            </div>
            <div>
              <p>
                L&rsquo;Utilisateur s&rsquo;engage, dans son usage des Services,
                &agrave; respecter les lois et r&egrave;glements en vigueur et
                &agrave; ne pas porter atteinte aux droits de tiers ou &agrave;
                l&rsquo;ordre public.
              </p>
            </div>
            <div>
              <p>
                Il est notamment seul responsable du bon accomplissement de
                toutes les formalit&eacute;s notamment administratives, fiscales
                et/ ou sociales et de tous les paiements de cotisations, taxes
                ou imp&ocirc;ts de toutes natures qui lui incombent, le cas
                &eacute;ch&eacute;ant, en relation avec son utilisation des
                Services. La responsabilit&eacute; de Simply-Fy ne pourra en
                aucun cas &ecirc;tre engag&eacute;e &agrave; ce titre.
              </p>
            </div>
            <div>
              <p>
                L&rsquo;Utilisateur reconna&icirc;t avoir pris connaissance sur
                le site des caract&eacute;ristiques et contraintes, notamment
                techniques, de l&rsquo;ensemble des Services. Il est seul
                responsable de son utilisation des Services.
              </p>
            </div>
            <div>
              <p>
                L&rsquo;Utilisateur est inform&eacute; et accepte que la mise en
                &oelig;uvre des Services n&eacute;cessite qu&rsquo;il soit
                connect&eacute; &agrave; internet et que la qualit&eacute; des
                Services d&eacute;pend directement de cette connexion, dont il
                est seul responsable.
              </p>
            </div>
            <div>
              <p>
                L&rsquo;Utilisateur est &eacute;galement seul responsable des
                relations qu&rsquo;il pourra nouer avec les autres Utilisateurs
                et des informations qu&rsquo;il leur communique dans le cadre
                des Services. Il lui appartient d&rsquo;exercer la prudence et
                le discernement appropri&eacute;s dans ces relations et
                communications. L&rsquo;Utilisateur s&rsquo;engage en outre,
                dans ses &eacute;changes avec les autres Utilisateurs, &agrave;
                respecter les r&egrave;gles usuelles de politesse et de
                courtoisie.
              </p>
            </div>
            <div>
              <p>
                L&rsquo;Utilisateur s&rsquo;engage &agrave; faire un usage
                strictement personnel des Services. Il s&rsquo;interdit en
                cons&eacute;quence de c&eacute;der, conc&eacute;der ou
                transf&eacute;rer tout ou partie de ses droits ou obligations au
                titre des pr&eacute;sentes &agrave; un tiers, de quelque
                mani&egrave;re que ce soit.
              </p>
            </div>
            <div>
              <p>
                L&rsquo;Utilisateur s&rsquo;engage &agrave; fournir &agrave;
                Simply-Fy toutes les informations n&eacute;cessaires &agrave; la
                bonne ex&eacute;cution des Services. Plus
                g&eacute;n&eacute;ralement, l&rsquo;Utilisateur s&rsquo;engage
                &agrave; coop&eacute;rer activement avec Simply-Fy en vue de la
                bonne ex&eacute;cution des pr&eacute;sentes.
              </p>
            </div>
            <div>
              <p>
                L&rsquo;Utilisateur est seul responsable des contenus de toute
                nature (r&eacute;dactionnels, graphiques, audiovisuels ou
                autres, en ce compris la d&eacute;nomination et/ou l&rsquo;image
                &eacute;ventuellement choisies par l&rsquo;Utilisateur pour
                l&rsquo;identifier sur le site) qu&rsquo;il diffuse dans le
                cadre des Services (ci-apr&egrave;s d&eacute;sign&eacute;s : les
                &laquo; <strong>Contenus</strong> &raquo;).
              </p>
            </div>
            <div>
              <p>
                Il garantit &agrave; Simply-Fy qu&rsquo;il dispose de tous les
                droits et autorisations n&eacute;cessaires &agrave; la diffusion
                de ces Contenus.
              </p>
            </div>
            <div>
              <p>
                Il s&rsquo;engage &agrave; ce que lesdits Contenus soient
                licites, ne portent pas atteinte &agrave; l&rsquo;ordre public,
                aux bonnes m&oelig;urs ou aux droits de tiers,
                n&rsquo;enfreignent aucune disposition l&eacute;gislative ou
                r&egrave;glementaire et plus g&eacute;n&eacute;ralement, ne
                soient aucunement susceptibles de mettre en jeu la
                responsabilit&eacute; civile ou p&eacute;nale de Simply-Fy.
              </p>
            </div>
            <div>
              <p>
                L&rsquo;Utilisateur s&rsquo;interdit ainsi de diffuser,
                notamment et sans que cette liste soit exhaustive :
              </p>
            </div>
            <div>
              <div>
                <ul>
                  <li>
                    des Contenus pornographiques, obsc&egrave;nes,
                    ind&eacute;cents, choquants ou inadapt&eacute;s &agrave; un
                    public familial, diffamatoires, injurieux, violents,
                    racistes, x&eacute;nophobes ou r&eacute;visionnistes,
                  </li>
                  <li>des Contenus contrefaisants,</li>
                  <li>
                    des Contenus attentatoires &agrave; l&rsquo;image d&rsquo;un
                    tiers,
                  </li>
                  <li>
                    des Contenus mensongers, trompeurs ou proposant ou
                    promouvant des activit&eacute;s illicites, frauduleuses ou
                    trompeuses,
                  </li>
                  <li>
                    des Contenus nuisibles aux syst&egrave;mes informatiques de
                    tiers (tels que virus, vers, chevaux de Troie, etc.),
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div>
                <ul>
                  <li>
                    et plus g&eacute;n&eacute;ralement des Contenus susceptibles
                    de porter atteinte aux droits de tiers ou d&rsquo;&ecirc;tre
                    pr&eacute;judiciables &agrave; des tiers, de quelque
                    mani&egrave;re et sous quelque forme que ce soit.
                  </li>
                </ul>
              </div>
              <div>
                <p>
                  L&rsquo;Utilisateur reconna&icirc;t que les Services lui
                  offrent une solution suppl&eacute;mentaire mais non
                  alternative des moyens qu&rsquo;il utilise d&eacute;j&agrave;
                  par ailleurs pour atteindre le m&ecirc;me objectif et que
                  cette solution ne saurait se substituer &agrave; ces autres
                  moyens.
                </p>
              </div>
              <div>
                <p>
                  L&rsquo;Utilisateur doit prendre les mesures
                  n&eacute;cessaires pour sauvegarder par ses propres moyens les
                  informations qu&rsquo;il juge n&eacute;cessaires, dont aucune
                  copie ne lui sera fournie.
                </p>
              </div>
            </div>
            <div>
              <p>
                L&rsquo;Utilisateur est inform&eacute; et accepte que la mise en
                &oelig;uvre des Services n&eacute;cessite qu&rsquo;il soit
                connect&eacute; &agrave; internet et que la qualit&eacute; des
                Services d&eacute;pend directement de cette connexion, dont il
                est seul responsable.
              </p>
            </div>
            <div>
              <p>
                <strong>10 &ndash; GARANTIE DE L&rsquo;UTILISATEUR</strong>
              </p>
            </div>
            <div>
              <p>
                L&rsquo;Utilisateur garantit Simply-Fy contre toutes plaintes,
                r&eacute;clamations, actions et/ou revendications quelconques
                que Simply-Fy pourrait subir du fait de la violation, par
                l&rsquo;Utilisateur de l&rsquo;une quelconque de ses obligations
                ou garanties aux termes des pr&eacute;sentes conditions
                g&eacute;n&eacute;rales.
              </p>
            </div>
            <div>
              <p>
                Il s&rsquo;engage &agrave; indemniser Simply-Fy de tout
                pr&eacute;judice qu&rsquo;elle subirait et &agrave; lui payer
                tous les frais, charges et/ou condamnations qu&rsquo;elle
                pourrait avoir &agrave; supporter de ce fait.
              </p>
            </div>
            <div>
              <p>
                <strong>11 &ndash; COMPORTEMENTS PROHIBES</strong>
              </p>
            </div>
            <div>
              <p>
                Il est strictement interdit d&rsquo;utiliser les Services aux
                fins suivantes :&nbsp;
              </p>
            </div>
            <div>
              <div>
                <ul>
                  <li>
                    l&rsquo;exercice d&rsquo;activit&eacute;s ill&eacute;gales,
                    frauduleuses ou portant atteinte aux droits ou &agrave; la
                    s&eacute;curit&eacute; des tiers,
                  </li>
                  <li>
                    l&rsquo;atteinte &agrave; l&rsquo;ordre public ou la
                    violation des lois et r&egrave;glements en vigueur,
                  </li>
                  <li>
                    l&rsquo;intrusion dans le syst&egrave;me informatique
                    d&rsquo;un tiers ou toute activit&eacute; de nature &agrave;
                    nuire, contr&ocirc;ler, interf&eacute;rer, ou intercepter
                    tout ou partie du syst&egrave;me informatique d&rsquo;un
                    tiers, en violer l&rsquo;int&eacute;grit&eacute; ou la
                    s&eacute;curit&eacute;,
                  </li>
                  <li>
                    l&rsquo;envoi d&rsquo;emails non sollicit&eacute;s et/ou de
                    prospection ou sollicitation commerciale,
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div>
                <ul>
                  <li>
                    les manipulations destin&eacute;es &agrave; am&eacute;liorer
                    le r&eacute;f&eacute;rencement d&rsquo;un site tiers,
                  </li>
                  <li>
                    l&rsquo;aide ou l&rsquo;incitation, sous quelque forme et de
                    quelque mani&egrave;re que ce soit, &agrave; un ou plusieurs
                    des actes et activit&eacute;s d&eacute;crits ci-dessus,
                  </li>
                  <li>
                    et plus g&eacute;n&eacute;ralement toute pratique
                    d&eacute;tournant les Services &agrave; des fins autres que
                    celles pour lesquelles ils ont &eacute;t&eacute;
                    con&ccedil;us.
                  </li>
                </ul>
              </div>
              <div>
                <p>
                  Il est strictement interdit aux Utilisateurs de copier et/ou
                  de d&eacute;tourner &agrave; leurs fins ou &agrave; celles de
                  tiers le concept, les technologies ou tout autre
                  &eacute;l&eacute;ment du site de Simply-Fy.
                </p>
              </div>
            </div>
            <div>
              <p>
                Sont &eacute;galement strictement interdits : (i) tous
                comportements de nature &agrave; interrompre, suspendre,
                ralentir ou emp&ecirc;cher la continuit&eacute; des Services,
                (ii) toutes intrusions ou tentatives d&rsquo;intrusions dans les
                syst&egrave;mes de Simply-Fy, (iii) tous d&eacute;tournements
                des ressources syst&egrave;me du site, (iv) toutes actions de
                nature &agrave; imposer une charge disproportionn&eacute;e sur
                les infrastructures de ce dernier, (v) toutes atteintes aux
                mesures de s&eacute;curit&eacute; et d&rsquo;authentification,
                (vi) tous actes de nature &agrave; porter atteinte aux droits et
                int&eacute;r&ecirc;ts financiers, commerciaux ou moraux de
                Simply-Fy ou des usagers de son site, et enfin plus
                g&eacute;n&eacute;ralement (vii) tout manquement aux
                pr&eacute;sentes conditions g&eacute;n&eacute;rales.
              </p>
            </div>
            <div>
              <p>
                Il est strictement interdit de monnayer, vendre ou
                conc&eacute;der tout ou partie de l&rsquo;acc&egrave;s aux
                Services ou au site, ainsi qu&rsquo;aux informations qui y sont
                h&eacute;berg&eacute;es et/ou partag&eacute;es.
              </p>
            </div>
            <div>
              <div>
                <p>
                  <strong>12 &ndash; SANCTIONS DES MANQUEMENTS</strong>
                </p>
              </div>
              <div>
                <p>
                  En cas de manquement &agrave; l&rsquo;une quelconque des
                  dispositions des pr&eacute;sentes conditions
                  g&eacute;n&eacute;rales ou plus g&eacute;n&eacute;ralement,
                  d&rsquo;infraction aux lois et r&egrave;glements en vigueur
                  par un Utilisateur, Simply-Fy se r&eacute;serve le droit de
                  prendre toute mesure appropri&eacute;e et notamment de :
                </p>
              </div>
              <div>
                <ol>
                  <li>
                    suspendre ou r&eacute;silier l&rsquo;acc&egrave;s aux
                    Services de l&rsquo;Utilisateur, auteur du manquement ou de
                    l&rsquo;infraction, ou y ayant particip&eacute;,
                  </li>
                </ol>
              </div>
              <div>
                <ol>
                  <li>supprimer tout contenu mis en ligne sur le site,</li>
                </ol>
              </div>
            </div>
            <div>
              <div>
                <ol>
                  <li>
                    publier sur le site tout message d&rsquo;information que
                    Simply-Fyjugera utile,
                  </li>
                </ol>
              </div>
              <div>
                <ol>
                  <li>avertir toute autorit&eacute; concern&eacute;e,</li>
                </ol>
              </div>
              <div>
                <ol>
                  <li>engager toute action judiciaire.</li>
                </ol>
              </div>
              <div>
                <p>
                  <strong>13 &ndash; RESPONSABILITE ET GARANTIE DE </strong>
                  <strong>SIMPLY-FY</strong>
                </p>
              </div>
            </div>
            <div>
              <p>
                Simply-Fy s&rsquo;engage &agrave; fournir les Services avec
                diligence et selon les r&egrave;gles de l&rsquo;art,
                &eacute;tant pr&eacute;cis&eacute; qu&rsquo;il p&egrave;se sur
                elle une obligation de moyens, &agrave; l&rsquo;exclusion de
                toute obligation de r&eacute;sultat, ce que les Utilisateurs
                reconnaissent et acceptent express&eacute;ment.
              </p>
            </div>
            <div>
              <p>
                Simply-Fy n&rsquo;a pas connaissance des Contenus mis en ligne
                par les Utilisateurs dans le cadre des Services, sur lesquels
                elle n&rsquo;effectue aucune mod&eacute;ration,
                s&eacute;lection, v&eacute;rification ou contr&ocirc;le
                d&rsquo;aucune sorte et &agrave; l&rsquo;&eacute;gard desquels
                elle n&rsquo;intervient qu&rsquo;en tant que prestataire
                d&rsquo;h&eacute;bergement.
              </p>
            </div>
            <div>
              <p>
                En cons&eacute;quence, Simply-Fy ne peut &ecirc;tre tenue pour
                responsable des Contenus, dont les auteurs sont des tiers, toute
                r&eacute;clamation &eacute;ventuelle devant &ecirc;tre
                dirig&eacute;e en premier lieu vers l&rsquo;auteur des Contenus
                en question.
              </p>
            </div>
            <div>
              <p>
                Les Contenus pr&eacute;judiciables &agrave; un tiers peuvent
                faire l&rsquo;objet d&rsquo;une notification &agrave; Simply-Fy
                selon les modalit&eacute;s pr&eacute;vues par l&rsquo;article 6
                I 5 de la loi n&deg; 2004-575 du 21 juin 2004 pour la confiance
                dans l&apos;&eacute;conomie num&eacute;rique, Simply-Fy se
                r&eacute;servant de prendre les mesures d&eacute;crites &agrave;
                l&rsquo;article 12.
              </p>
            </div>
            <div>
              <p>
                Simply-Fy d&eacute;cline toute responsabilit&eacute; en cas de
                perte &eacute;ventuelle des informations de l&rsquo;Utilisateur,
                celui-ci devant en sauvegarder une copie et ne pouvant
                pr&eacute;tendre &agrave; aucun d&eacute;dommagement &agrave; ce
                titre.
              </p>
            </div>
            <div>
              <p>
                Simply-Fy s&rsquo;engage &agrave; proc&eacute;der
                r&eacute;guli&egrave;rement &agrave; des contr&ocirc;les afin de
                v&eacute;rifier le fonctionnement et
                l&rsquo;accessibilit&eacute; du site. A ce titre
                <strong>, </strong>Simply-Fy se r&eacute;serve la facult&eacute;
                d&rsquo;interrompre momentan&eacute;ment l&rsquo;acc&egrave;s au
                site pour des raisons de maintenance. De m&ecirc;me, Simply-Fy
                ne saurait &ecirc;tre tenue responsable des difficult&eacute;s
                ou impossibilit&eacute;s momentan&eacute;es d&rsquo;acc&egrave;s
                au site qui auraient pour origine des circonstances qui lui sont
                ext&eacute;rieures, la force majeure, ou encore qui seraient
                dues &agrave; des perturbations des r&eacute;seaux de
                t&eacute;l&eacute;communication.
              </p>
            </div>
            <div>
              <p>
                Simply-Fy ne garantit pas aux Utilisateurs (i) que les Services,
                soumis &agrave; une recherche constante pour en am&eacute;liorer
                notamment la performance et le progr&egrave;s, seront totalement
                exempts d&rsquo;erreurs, de vices ou d&eacute;fauts, (ii) que
                les Services, &eacute;tant standard et nullement propos&eacute;s
                &agrave; la seule intention d&rsquo;un Utilisateur donn&eacute;
                en fonction de ses propres contraintes personnelles,
                r&eacute;pondront sp&eacute;cifiquement &agrave; ses besoins et
                attentes.
              </p>
            </div>
            <div>
              <p>
                En tout &eacute;tat de cause, la responsabilit&eacute;
                susceptible d&rsquo;&ecirc;tre encourue par Simply-Fy au titre
                des pr&eacute;sentes est express&eacute;ment limit&eacute;e aux
                seuls dommages directs av&eacute;r&eacute;s subis par
                l&rsquo;Utilisateur.
              </p>
            </div>
            <div>
              <p>
                <strong>14 &ndash; PROPRIETE INTELLECTUELLE</strong>
              </p>
            </div>
            <div>
              <p>
                Les syst&egrave;mes, logiciels, structures, infrastructures,
                bases de donn&eacute;es et contenus de toute nature (textes,
                images, visuels, musiques, logos, marques, base de
                donn&eacute;es, etc &hellip;) exploit&eacute;s par Simply-Fy au
                sein du site sont prot&eacute;g&eacute;s par tous droits de
                propri&eacute;t&eacute; intellectuelle ou droits des producteurs
                de bases de donn&eacute;es en vigueur. Tous
                d&eacute;sassemblages, d&eacute;compilations,
                d&eacute;cryptages, extractions, r&eacute;utilisations, copies
                et plus g&eacute;n&eacute;ralement, tous actes de reproduction,
                repr&eacute;sentation, diffusion et utilisation de l&rsquo;un
                quelconque de ces &eacute;l&eacute;ments, en tout ou partie,
                sans l&rsquo;autorisation de Simply-Fy sont strictement
                interdits et pourront faire l&rsquo;objet de poursuites
                judiciaires.
              </p>
            </div>
            <div>
              <p>
                <strong>15 &ndash; DONNEES A CARACT&Egrave;RE PERSONNEL</strong>
              </p>
            </div>
            <div>
              <p>
                Simply-Fy pratique une politique de protection des
                donn&eacute;es personnelles dont les caract&eacute;ristiques
                sont explicit&eacute;es dans le document intitul&eacute;{' '}
                <em>&laquo; </em>
                <em>Charte de confidentialit&eacute; &raquo;</em>
                <em>,</em> dont l&rsquo;Utilisateur est express&eacute;ment
                invit&eacute; &agrave; prendre connaissance sur le site.
              </p>
            </div>
            <div>
              <p>
                <strong>16 &ndash; PUBLICITE</strong>
              </p>
            </div>
            <div>
              <p>
                Simply-Fy se r&eacute;serve la facult&eacute;
                d&rsquo;ins&eacute;rer sur toute page du site et dans toute
                communication aux Utilisateurs tous messages publicitaires ou
                promotionnels sous une forme et dans des conditions dont
                Simply-Fy sera seule juge.
              </p>
            </div>
            <div>
              <p>
                <strong>17 &ndash; LIENS ET SITES TIERS</strong>
              </p>
            </div>
            <div>
              <p>
                Simply-Fy ne pourra en aucun cas &ecirc;tre tenue pour
                responsable de la disponibilit&eacute; technique de sites
                internet ou d&rsquo;applications mobiles exploit&eacute;s par
                des tiers (y compris ses &eacute;ventuels partenaires) auxquels
                l&rsquo;Utilisateur acc&eacute;derait par
                l&apos;interm&eacute;diaire du site.
              </p>
            </div>
            <div>
              <p>
                Simply-Fy n&apos;endosse aucune responsabilit&eacute; au titre
                des contenus, publicit&eacute;s, produits et/ou services
                disponibles sur de tels sites et applications mobiles tiers dont
                il est rappel&eacute; qu&rsquo;ils sont r&eacute;gis par leurs
                propres conditions d&rsquo;utilisation.
              </p>
            </div>
            <div>
              <p>
                Simply-Fy n&apos;est pas non plus responsable des transactions
                intervenues entre l&rsquo;Utilisateur et un quelconque
                annonceur, professionnel ou commer&ccedil;ant (y compris ses
                &eacute;ventuels partenaires) vers lequel l&rsquo;Utilisateur
                serait orient&eacute; par l&apos;interm&eacute;diaire du site et
                ne saurait en aucun cas &ecirc;tre partie &agrave; quelques
                litiges &eacute;ventuels que ce soit avec ces tiers concernant
                notamment la livraison de produits et/ou services, les
                garanties, d&eacute;clarations et autres obligations quelconques
                auxquelles ces tiers sont tenus.
              </p>
            </div>
            <div>
              <p>
                <strong>18 &ndash; DUREE DES SERVICES, DESINSCRIPTION</strong>
              </p>
            </div>
            <div>
              <p>
                Les Services sont souscrits pour une dur&eacute;e
                ind&eacute;termin&eacute;e.
              </p>
            </div>
            <div>
              <p>
                L&rsquo;Utilisateur peut se d&eacute;sinscrire des Services
                &agrave; tout moment, en adressant une demande &agrave; cet
                effet &agrave; Simply-Fy par email, aux coordonn&eacute;es
                mentionn&eacute;es &agrave; l&rsquo;article 2.
              </p>
            </div>
            <div>
              <p>
                La d&eacute;sinscription est effective imm&eacute;diatement.
                Elle entra&icirc;ne la suppression automatique du Compte de
                l&rsquo;Utilisateur.
              </p>
            </div>
            <div>
              <p>
                <strong>19 &ndash; MODIFICATIONS</strong>
              </p>
            </div>
            <div>
              <p>
                Simply-Fy se r&eacute;serve la facult&eacute; de modifier
                &agrave; tout moment les pr&eacute;sentes conditions
                g&eacute;n&eacute;rales.&nbsp;
              </p>
            </div>
            <div>
              <p>
                L&rsquo;Utilisateur sera inform&eacute; de ces modifications par
                tout moyen utile.
              </p>
            </div>
            <div>
              <p>
                L&rsquo;Utilisateur qui n&rsquo;accepte pas les conditions
                g&eacute;n&eacute;rales modifi&eacute;es doit se
                d&eacute;sinscrire des Services selon les modalit&eacute;s
                pr&eacute;vues &agrave; l&rsquo;article 18.&nbsp;
              </p>
            </div>
            <div>
              <p>
                Tout Utilisateur qui a recours aux Services
                post&eacute;rieurement &agrave; l&rsquo;entr&eacute;e en vigueur
                des conditions g&eacute;n&eacute;rales modifi&eacute;es est
                r&eacute;put&eacute; avoir accept&eacute; ces modifications.
              </p>
            </div>
            <div>
              <p>
                <strong>20 &ndash; LANGUE</strong>
              </p>
            </div>
            <div>
              <p>
                Dans l&rsquo;hypoth&egrave;se d&rsquo;une traduction des
                pr&eacute;sentes conditions g&eacute;n&eacute;rales dans une ou
                plusieurs langues, la langue d&rsquo;interpr&eacute;tation sera
                la langue fran&ccedil;aise en cas de contradiction ou de
                contestation sur la signification d&rsquo;un terme ou
                d&rsquo;une disposition.
              </p>
            </div>
            <div>
              <p>
                <strong>21&ndash; LOI APPLICABLE ET JURIDICTION</strong>
              </p>
            </div>
            <div>
              <p>
                Les pr&eacute;sentes conditions g&eacute;n&eacute;rales sont
                r&eacute;gies par la loi fran&ccedil;aise.
              </p>
            </div>
            <div>
              <p>
                En cas de contestation sur la validit&eacute;,
                l&rsquo;interpr&eacute;tation et/ou l&rsquo;ex&eacute;cution des
                pr&eacute;sentes conditions g&eacute;n&eacute;rales, les parties
                conviennent que les tribunaux de Paris seront exclusivement
                comp&eacute;tents pour en juger, sauf r&egrave;gles de
                proc&eacute;dure imp&eacute;ratives contraires.
              </p>
            </div>
            <div>
              <p>
                <strong>22 &ndash; ENTREE EN VIGUEUR</strong>
              </p>
            </div>
            <div>
              <p>
                Les pr&eacute;sentes conditions g&eacute;n&eacute;rales sont
                entr&eacute;es en vigueur le 14 juin 2023.
              </p>
            </div>
          </Grid>
        </Grid>
      </PageWrapper>
    </div>
  );
}
