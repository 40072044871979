import React from 'react';
import PageWrapper from '../components/PageWrapper';
import Head from '../components/head';
import BlockImage from '../components/blockImage';
import Steps from '../components/StepsTwo';
import { Helmet } from 'react-helmet';

export default function GuidPage() {
  return (
    <div className='page-guid'>
      <Helmet>
        <title>Comment ça marche ? - Click & Fi</title>
        <meta
          name='description'
          content='Découvrez comment Click & Fi vous accompagne pour trouver la meilleure solution de location et de financement pour vos projets auto. Nos conseillers experts sont là pour vous guider tout au long de votre projet.'
        />
        <link
          rel='canonical'
          href='https://www.click-and-fi.fr/comment-ca-marche'
        />
      </Helmet>
      <PageWrapper>
        <Head>
          <b>Comment ça marche ?</b>
        </Head>
        <BlockImage>
          <div className='content content-two'>
            <div className='block-head'>
              <h3>
                <span className='top'>
                  CLICK <b>& FI</b>
                </span>
                <b>
                  <span className='bottom'>
                    RENOUVELER
                    <br />
                    <span className='highlight'>PROLONGER</span>
                  </span>
                </b>
              </h3>
            </div>
            <div className='block-body'>
              <p>
                Envie d’un nouveau véhicule en location ou Envie de prolonger sa
                location en cours ? Click & fi est là pour vous accompagner.
                Click & fi, la 1ère place de marché dédié à la prolongation des
                contrats de Location automobile.
              </p>
            </div>
          </div>
          <img src='/images/blockimage2.jpeg' alt='block-image' />
        </BlockImage>
        <Steps />
      </PageWrapper>
    </div>
  );
}
