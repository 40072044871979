import React, { useEffect, useState } from 'react';
import Grid from './grid';
import Button from './button';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as yup from 'yup';
import {
  radioUncheck,
  numberPlateFormat,
  setUTMValues,
  isDev,
} from '../shared/helpers';
import { getPartner } from '../shared/Partner';
import ReCAPTCHA from 'react-google-recaptcha';

import { yupResolver } from '@hookform/resolvers/yup';

// fields name
const nameKilometrage = isDev ? '00N7a00000JXRLk' : '00N68000004KdzV';
const nameBudget = isDev ? '00N7a00000KWxCT' : '00N68000004Kp9r';
const nameDate = isDev ? '00N7a00000JWOoH' : '00N68000004KdzY';
const nameHoure = isDev ? '00N7a00000KWxCs' : '00N68000004KpAa';
const nameControle = 'controle';
const nameControle2 = isDev ? '00N7a00000JXRR0' : '00N68000004Kdza';
const nameImmatruculation = isDev ? '00N7a00000JXRQb' : '00N68000004KdzX';
const nameSerie = isDev ? '00N7a00000KTLCF' : '00N68000004KpAu';
const nameStatus = isDev ? '00N7a00000JXRQW' : '00N68000004KdzQ';
const nameOrganisme = isDev ? '00N7a00000KWxMB' : '00N68000004KpAV';
const nameMensualites = isDev ? '00N7a00000KWxMf' : '00N68000004KpAL';
const nameMaintained = 'maintained';
const nameMaintained2 = isDev ? '00N7a00000KWxMk' : '00N68000004KpAB';
const nameCodePartner = isDev ? '00N7a00000KXVxy' : '00N68000004KpA6';
const nameAandT = '00N7a00000KxuJz';

const schema = yup.object().shape({
  first_name: yup.string().max(40).required(),
  last_name: yup.string().max(80).required(),
  city: yup.string().max(40).required(),
  email: yup
    .string()
    .email("S'il vous plaît, mettez une adresse email valide")
    .max(80)
    .required(),
  [nameKilometrage]: yup.number().typeError('doit être un nombre').required(), // Kilométrage
  [nameBudget]: yup.number().typeError('doit être un nombre').required(), // Budget
  [nameDate]: yup.string().required(), // Date
  [nameHoure]: yup.string().required(), // Houre
  [nameControle]: yup.string().required(), // Controle
  [nameImmatruculation]: yup
    .string()
    .matches(/^[A-Z]{2}-\d{3}-[A-Z]{2}$/, "Ce champ n'est pas valide")
    .required(), // Immatruculation
  [nameSerie]: yup
    .string()
    .notRequired()
    .matches(
      /^[a-zA-Z0-9]*$/,
      'Le champ doit contenir uniquement des lettres et des chiffres'
    )
    .test('len', '17 caractères requis', (value) => {
      if (!value) return true; // Skip validation if value is not entered
      return value.length === 17;
    }), // Serie
  [nameStatus]: yup.string().max(100).required(), // Status
  [nameOrganisme]: yup.string().max(100).required(), // Organisme
  [nameMensualites]: yup.string().required(), // Mensualités
  [nameMaintained]: yup.string().required(), // Maintained
  [nameCodePartner]: yup.string(), //Code Partner
  [nameAandT]: yup
    .string()
    .oneOf(['1'], 'Vous devez accepter les Termes')
    .required('Vous devez accepter les Termes'),
});

const statusOptions = [
  { value: 'Bon état', label: 'Bon état' },
  { value: 'État standard', label: 'État standard' },
  { value: 'Mauvais état', label: 'Mauvais état' },
];

const houres = [
  { value: '8h-10h', label: '8h-10h' },
  { value: '10h-12h', label: '10h-12h' },
  { value: '12h-14h', label: '12h-14h' },
  { value: '14h-16h', label: '14h-16h' },
  { value: '16h-18h', label: '16h-18h' },
  { value: '18h-20h', label: '18h-20h' },
];

export default function FormProlong({
  spaceMember,
}: {
  spaceMember?: boolean;
}) {
  const {
    control,
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [captcha, setCaptcha] = useState({ isNotRobot: false, error: false });

  const [recaptchaToken, setRecaptchaToken] = useState<string>('');

  function onChange(value: any) {
    if (value) {
      setCaptcha({ isNotRobot: true, error: false });
    }
  }

  numberPlateFormat();

  const watchCity = watch('city');

  useEffect(() => {
    if (watchCity) {
      setValue('city', watchCity.toUpperCase());
    }
  }, [watchCity]);

  useEffect(() => {
    setRecaptchaToken(process.env.REACT_APP_RECAPTCHA_TOKEN || '');
    const asyncPartner = async () => {
      const partner = await getPartner();
      const leadSourceInput: any = document.getElementsByName('lead_source')[0];
      if (partner && partner.code) {
        const partnerInput: any =
          document.getElementsByName(nameCodePartner)[0];
        partnerInput.value = partner.code;

        leadSourceInput.value = 'Apport_affaire';
      } else {
        leadSourceInput.value = 'Formulaire_site_web';
      }
    };
    asyncPartner();
    setUTMValues();
  }, []);

  const onSubmit = (data: any, e: any) => {
    if (!captcha.isNotRobot) {
      setCaptcha({ isNotRobot: false, error: true });
      return;
    }

    const el1 = document.getElementsByName(
      nameControle2
    )[0] as HTMLInputElement;
    const el2 = document.getElementsByName(
      nameMaintained2
    )[0] as HTMLInputElement;
    console.log('check');
    if (data.controle == '1') {
      el1.checked = true;
    } else {
      el1.checked = false;
    }
    if (data.maintained == '1') {
      el2.checked = true;
    } else {
      el2.checked = false;
    }
    setTimeout(() => {
      e.target.submit();
    }, 0);
  };

  useEffect(() => {
    // radio uncheck
    radioUncheck(getValues, setValue);
  }, [watch]);

  const renderRecaptcha = () => {
    return (
      <div className='form-captch'>
        <ReCAPTCHA sitekey={recaptchaToken} onChange={onChange} />

        {captcha.error && (
          <p className='error error-captch'>
            Le captcha est invalide. Veuillez réessayer.
          </p>
        )}
      </div>
    );
  };

  const getRetUrlValue = () => {
    if (spaceMember) {
      return `${
        isDev ? 'https://click-and-fi.lagentx.tech' : 'https://click-and-fi.fr'
      }/espace-partenaire?target=prolonger&statut=succes`;
    } else {
      return `${
        isDev ? 'https://click-and-fi.lagentx.tech' : 'https://click-and-fi.fr'
      }/prolonger?statut=succes`;
    }
  };

  return (
    <div className='block-prolong' id='form'>
      <Grid container maxWidth='lg' columnSpacing={8}>
        <Grid xs={12} md={10} mdOffset={0}>
          <h3>
            Contacter un conseiller pour personnaliser votre financement
            <span className='small'>ET ÊTRE RECONTACTÉ DANS LES 24H</span>
          </h3>
        </Grid>
        <Grid
          xs={12}
          sx={{
            p: {
              xs: 0,
              md: 2,
            },
          }}
        >
          <form
            autoComplete='off'
            className='form'
            id='form-prolonger'
            action={`https://${
              isDev ? 'test' : 'webto'
            }.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8`}
            method='POST'
            onSubmit={handleSubmit(onSubmit)}
          >
            <input
              className='hidden'
              type='checkbox'
              name={nameControle2}
              value='1'
            />
            <input
              className='hidden'
              type='checkbox'
              name={nameMaintained2}
              value='1'
            />
            <input
              type='hidden'
              name='recordType'
              id='recordType'
              value={isDev ? '0127a000002pH7l' : '01268000000HkFa'}
            />
            <input
              type='hidden'
              name='oid'
              value={isDev ? '00D7a00000055rv' : '00D68000002836s'}
            />
            <input type='hidden' name='retURL' value={getRetUrlValue()} />
            {/* code partner*/}
            <input type='hidden' id={nameCodePartner} name={nameCodePartner} />

            {/* UTM */}
            <input id='utm_source' name='00N68000004KpCM' type='hidden' />
            <input id='utm_medium' name='00N68000004KpCR' type='hidden' />
            <input id='utm_term' name='00N68000004KpCW' type='hidden' />
            <input id='utm_campaign' name='00N68000004KpCb' type='hidden' />
            <input id='utm_content' name='00N68000004KpCg' type='hidden' />

            <input id='lead_source' name='lead_source' type='hidden' />

            <Grid container maxWidth='lg' className='form-content' spacing={0}>
              <Grid
                xs={12}
                md={5}
                mdOffset={0}
                sx={{
                  my: 0,
                }}
              >
                <p className='form-title'>Informations personnelles</p>
                <Grid
                  container
                  maxWidth='lg'
                  className='form-controllers'
                  spacing={0}
                >
                  <Grid xs={12} md={5.5} mdOffset={0}>
                    <label className='form-label' htmlFor='first_name'>
                      Prénom*
                    </label>
                    <input
                      className='form-control'
                      placeholder='Jean'
                      {...register('first_name')}
                    />

                    {errors['first_name']?.message && (
                      <p className='error'>
                        {errors['first_name'] &&
                          (errors['first_name'] as { message: string }).message}
                      </p>
                    )}
                  </Grid>
                  <Grid xs={12} md={5.5} mdOffset={1}>
                    <label className='form-label' htmlFor='last_name'>
                      Nom*
                    </label>
                    <input
                      className='form-control'
                      placeholder='Dupont'
                      {...register('last_name')}
                    />
                    {errors['last_name']?.message && (
                      <p className='error'>
                        {errors['last_name'] &&
                          (errors['last_name'] as { message: string }).message}
                      </p>
                    )}
                  </Grid>
                  <Grid xs={12} md={5.5} mdOffset={0}>
                    <label className='form-label' htmlFor='phone'>
                      Téléphone*
                    </label>
                    <input
                      className='form-control'
                      placeholder='+33'
                      {...register('phone')}
                    />
                    {errors['phone']?.message && (
                      <p className='error'>
                        {errors['phone'] &&
                          (errors['phone'] as { message: string }).message}
                      </p>
                    )}
                  </Grid>
                  <Grid xs={12} md={5.5} mdOffset={1}>
                    <label className='form-label' htmlFor='city'>
                      Ville*
                    </label>
                    <input
                      className='form-control'
                      placeholder='Lille'
                      {...register('city')}
                    />

                    {errors['city']?.message && (
                      <p className='error'>
                        {errors['city'] &&
                          (errors['city'] as { message: string }).message}
                      </p>
                    )}
                  </Grid>
                  <Grid xs={12} md={12}>
                    <label className='form-label' htmlFor='email'>
                      Email*
                    </label>
                    <input
                      className='form-control'
                      placeholder='contact@gmail.com'
                      {...register('email')}
                    />
                    {errors['email']?.message && (
                      <p className='error'>
                        {errors['email'] &&
                          (errors['email'] as { message: string }).message}
                      </p>
                    )}
                  </Grid>
                  <Grid xs={12} md={12}>
                    <label className='form-label' htmlFor='date'>
                      Date souhaitée*
                    </label>
                    <Controller
                      control={control}
                      name={nameDate}
                      render={({ field }) => (
                        <DatePicker
                          dateFormat='dd/MM/yyyy'
                          {...register(nameDate)}
                          placeholderText='00/00/2023'
                          className='form-control'
                          onChange={(date: any) => field.onChange(date)}
                          selected={field.value}
                          minDate={new Date()}
                        />
                      )}
                    />
                    {errors[nameDate]?.message && (
                      <p className='error'>
                        {errors[nameDate] &&
                          (errors[nameDate] as { message: string }).message}
                      </p>
                    )}
                  </Grid>
                  <Grid xs={12} md={12}>
                    <label className='form-label' htmlFor='status'>
                      Tranche horaire*
                    </label>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({
                        field: { onChange: OnHourlychanged, onBlur, value },
                      }) => (
                        <Select
                          {...register(nameHoure)}
                          className='form-control form-control-select'
                          options={houres}
                          onBlur={onBlur}
                          onChange={(data: any) => {
                            OnHourlychanged(data.value);
                          }}
                          value={value ? { value, label: value } : null}
                          placeholder='00h-00h'
                        />
                      )}
                      name={nameHoure}
                    />

                    {errors[nameHoure]?.message && (
                      <p className='error'>
                        {errors[nameHoure] &&
                          (errors[nameHoure] as { message: string }).message}
                      </p>
                    )}
                  </Grid>
                  <Grid xs={12} md={12} mdOffset={0}>
                    <label className='form-label'>
                      Contrôle technique à jour ?{' '}
                      <span className='new-line'>(-6 mois)</span>
                    </label>
                    <br />
                    <input
                      type='radio'
                      value='1'
                      id='controle_oui'
                      {...register('controle')}
                    />
                    <label htmlFor='controle_oui'>Oui</label>
                    <input
                      type='radio'
                      value='0'
                      id='controle_no'
                      {...register('controle')}
                    />
                    <label htmlFor='controle_no'>Non</label>
                    {errors['controle']?.message && (
                      <p className='error'>
                        {errors['controle'] &&
                          (errors['controle'] as { message: string }).message}
                      </p>
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Grid xs={12} md={5} mdOffset={2}>
                <p className='form-title'>Informations du véhicule</p>
                <Grid
                  container
                  maxWidth='lg'
                  className='form-controllers'
                  spacing={0}
                >
                  <Grid xs={12} md={12}>
                    <label className='form-label' htmlFor={nameImmatruculation}>
                      Plaque d’immatriculation*
                    </label>
                    <input
                      id='imm-input'
                      className='form-control'
                      placeholder='AA-000-AA'
                      {...register(nameImmatruculation)}
                    />

                    {errors[nameImmatruculation]?.message && (
                      <p className='error'>
                        {errors[nameImmatruculation] &&
                          (errors[nameImmatruculation] as { message: string })
                            .message}
                      </p>
                    )}
                  </Grid>
                  <Grid xs={12} md={12}>
                    <label className='form-label' htmlFor={nameSerie}>
                      N° de série du véhicule *
                      <div className='form-control-info'>
                        <div className='icon'>i</div>
                        <div className='info-content'>
                          <span>(E) sur la carte grise</span>
                        </div>
                      </div>
                    </label>
                    <input
                      className='form-control'
                      placeholder='VFSIV2009ASIV2009'
                      {...register(nameSerie)}
                    />

                    {errors[nameSerie]?.message && (
                      <p className='error'>
                        {errors[nameSerie] &&
                          (errors[nameSerie] as { message: string }).message}
                      </p>
                    )}
                  </Grid>

                  <Grid xs={12} md={12}>
                    <label className='form-label' htmlFor={nameStatus}>
                      État de la voiture*
                    </label>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({
                        field: { onChange: onCarStatusChanged, onBlur, value },
                      }) => (
                        <Select
                          {...register(nameStatus)}
                          className='form-control form-control-select'
                          options={statusOptions}
                          onBlur={onBlur}
                          onChange={(data: any) => {
                            onCarStatusChanged(data.value);
                          }}
                          value={value ? { value, label: value } : null}
                        />
                      )}
                      name={nameStatus}
                    />

                    {errors[nameStatus]?.message && (
                      <p className='error'>
                        {errors[nameStatus] &&
                          (errors[nameStatus] as { message: string }).message}
                      </p>
                    )}
                  </Grid>
                  <Grid xs={12} md={12}>
                    <label className='form-label' htmlFor={nameOrganisme}>
                      Organisme de financement du titulaire de la carte grise ?*
                    </label>

                    <input
                      className='form-control'
                      placeholder='organisme'
                      {...register(nameOrganisme)}
                    />
                    {errors[nameOrganisme]?.message && (
                      <p className='error'>
                        {errors[nameOrganisme] &&
                          (errors[nameOrganisme] as { message: string })
                            .message}
                      </p>
                    )}
                  </Grid>
                  <Grid xs={12} md={5.5} mdOffset={0}>
                    <label className='form-label' htmlFor={nameKilometrage}>
                      Kilométrage*
                    </label>
                    <input
                      className='form-control'
                      placeholder='100 000'
                      {...register(nameKilometrage)}
                    />
                    {errors[nameKilometrage]?.message && (
                      <p className='error'>
                        {errors[nameKilometrage] &&
                          (errors[nameKilometrage] as { message: string })
                            .message}
                      </p>
                    )}
                  </Grid>
                  <Grid xs={12} md={5.5} mdOffset={1}>
                    <label className='form-label' htmlFor={nameMensualites}>
                      Mensualités actuelles*
                    </label>
                    <input
                      className='form-control'
                      placeholder='500'
                      {...register(nameMensualites)}
                    />
                    {errors[nameMensualites]?.message && (
                      <p className='error'>
                        {errors[nameMensualites] &&
                          (errors[nameMensualites] as { message: string })
                            .message}
                      </p>
                    )}
                  </Grid>
                  <Grid
                    xs={12}
                    md={12}
                    mdOffset={0}
                    sx={{
                      my: {
                        xs: 1,
                      },
                    }}
                  >
                    <label className='form-label'>
                      Entretenue dans le réseau constructeur ?*
                    </label>
                    <br />
                    <input
                      type='radio'
                      value='1'
                      id='q1_oui'
                      {...register('maintained')}
                    />
                    <label htmlFor='q1_oui'>Oui</label>
                    <input
                      type='radio'
                      id='q1_no'
                      value='0'
                      {...register('maintained')}
                    />
                    <label htmlFor='q1_no'>Non</label>
                    {errors['maintained']?.message && (
                      <p className='error'>
                        {errors['maintained'] &&
                          (errors['maintained'] as { message: string }).message}
                      </p>
                    )}
                  </Grid>
                  <Grid xs={12} md={12}>
                    <label className='form-label' htmlFor='budget'>
                      Budget mensuel souhaité*
                    </label>
                    <input
                      className='form-control'
                      placeholder='250'
                      {...register(nameBudget)}
                    />
                    {errors[nameBudget]?.message && (
                      <p className='error'>
                        {errors[nameBudget] &&
                          (errors[nameBudget] as { message: string }).message}
                      </p>
                    )}
                  </Grid>
                  <Grid
                    xs={12}
                    md={12}
                    className='form-control-wrapper form-control-wrapper-checkbox'
                  >
                    <input
                      type='checkbox'
                      className='form-control'
                      value='1'
                      id={nameAandT}
                      {...register(nameAandT)}
                    />
                    <label htmlFor={nameAandT} className='tandc'>
                      Je reconnais que les champs marqués d’un astérisque sont
                      obligatoires. Click & FI traite les données recueillies
                      pour répondre à votre demande de renseignement. Pour en
                      savoir plus sur la gestion de vos données personnelles et
                      pour exercer vos droits, reportez-vous à la politique de
                      confidentialité.
                    </label>
                    {errors[nameAandT]?.message && (
                      <p className='error'>
                        {errors[nameAandT] &&
                          (errors[nameAandT] as { message: string }).message}
                      </p>
                    )}
                  </Grid>

                  {recaptchaToken && renderRecaptcha()}

                  <Button text='Envoyer' submit dark />
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </div>
  );
}
