import React, { useEffect } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import { ScrollRestoration } from 'react-router-dom';
import ReactGA from 'react-ga';

function PageWrapper({ children }: any) {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    console.log(window.location.pathname + window.location.search);
  }, []);
  return (
    <>
      <ScrollRestoration />
      <Header />
      {children}
      <Footer />
    </>
  );
}

export default PageWrapper;
