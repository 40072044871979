let initialState = {
  loading: false,
};

export default function appReducer(state = initialState, action = '') {
  if (action.type == 'START_LOADING') {
    return Object.assign({}, state, { loading: true });
  } else if (action.type == 'STOP_LOADING') {
    return Object.assign({}, state, { loading: false });
  } else {
    return state;
  }
}
