import React, { useState, useEffect } from "react";
import PageWrapper from "../components/PageWrapper";
import FormLogin from "../components/formLogin";
import { useNavigate } from "react-router-dom";
import { getPartner } from "../shared/Partner";
import { connect } from "react-redux";
import LoadingOverlay from "../components/Loading";
import {Helmet} from "react-helmet";

const ConnectPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<null | boolean>(true);
  useEffect(() => {
    getPartner(true)
      .then((partner: any) => {
        if (partner && partner?.code) {
          navigate("/espace-partenaire");
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <div className="page-connect">
      <Helmet>
        <title>Connexion - Click & Fi</title>
        <meta name="description" content="Connectez-vous à l'espace partenaire de Click & Fi grâce à votre code partenaire." />
        <link rel="canonical" href="https://www.click-and-fi.fr/connexion" />
      </Helmet>
      {loading ? (
        <LoadingOverlay />
      ) : (
        <PageWrapper>
          <FormLogin />
        </PageWrapper>
      )}
    </div>
  );
};

function mapStateToProps(state: any) {
  return { state };
}

export default connect(mapStateToProps)(ConnectPage);
