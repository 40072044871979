import React, { useState, useEffect } from 'react';
import Grid from './grid';
import Button from './button';
import { getPartner } from '../shared/Partner';

export default function StepsTwo() {
  const [user, setUser] = useState<null | { color: string }>(null);

  useEffect(() => {
    getPartner().then((partner) => {
      if (partner && partner.code) {
        setUser(partner);
      }
    });
  }, []);
  return (
    <div className='steps-two-wrapper'>
      <Grid container maxWidth='lg' className='steps-two' spacing={0}>
        <Grid sm={12} md={12} className='title'>
          <h3>Les différentes étapes</h3>
        </Grid>
        <Grid sm={12} md={12} className='cards'>
          <Grid
            container
            rowSpacing={{
              xs: 3,
            }}
            columnSpacing={{
              md: 2,
            }}
          >
            <Grid sm={12} md={12}>
              <div className='card'>
                <div className='card-content'>
                  <div className='card-left'>
                    <p className='card-title'>
                      <span className='number'>1</span>
                      <span className='t-text'>
                        Remplir notre formulaire de contact
                      </span>
                    </p>
                    <p className='card-body'>
                      Avec vos informations personnelles, afin qu’un de nos
                      experts étudie votre dossier et valide votre éligibilité.
                    </p>
                  </div>
                  <div className='card-right'>
                    <div className='btns'>
                      <Button
                        text='Prolonger'
                        to={
                          user
                            ? '/espace-partenaire?target=prolonger'
                            : '/prolonger'
                        }
                        dark
                      />
                      <Button
                        text='Renouveler'
                        to={
                          user
                            ? '/espace-partenaire?target=renouveler'
                            : '/renouveler'
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
            {/*
            <Grid sm={12} md={6}>
              <div className="card">
                <div className="card-content">
                  <p className="card-title">
                    <span className="number">2</span>
                    <span className="t-text">Mail de confirmation</span>
                  </p>
                  <p className="card-body">
                    Vous recevez un mail de confirmation indiquant le traitement
                    de votre demande
                  </p>
                </div>
              </div>
            </Grid>
            <Grid sm={12} md={6}>
              <div className="card">
                <div className="card-content">
                  <p className="card-title">
                    <span className="number">3</span>
                    <span className="t-text">Lien à remplir</span>
                  </p>
                  <p className="card-body">
                    Vous allez recevoir un lien afin de compléter le reste des
                    informations.
                  </p>
                </div>
              </div>
            </Grid>
              */}
            <Grid sm={12} md={6}>
              <div className='card'>
                <div className='card-content'>
                  <p className='card-title'>
                    <span className='number'>2</span>
                    <span className='t-text'>Demande de contact</span>
                  </p>
                  <p className='card-body'>
                    Nous prendrons contact avec vous sous 48h pour vous
                    présenter plusieurs alternatives afin de sécuriser au mieux
                    votre patrimoine automobile tout en maitrisant votre budget.
                  </p>
                </div>
              </div>
            </Grid>
            <Grid sm={12} md={6}>
              <div className='card'>
                <div className='card-content'>
                  <p className='card-title'>
                    <span className='number'>3</span>
                    <span className='t-text'>Prise de rendez-vous</span>
                  </p>
                  <p className='card-body'>
                    La proposition vous satisfait et vous souhaitez y souscrire
                    ? Rien de plus simple, nous prenons rendez-vous ensemble
                    pour signer votre nouveau dossier de financement à distance.
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
