import React from "react";
import Grid from "../components/grid";
import LeftSide from "./LeftSide";
import RightSide from "./RightSide";
const SmallIntro = () => {
  return (
    <Grid container maxWidth="lg" className="small-intro">
      <Grid container xs={12} md={5} lg={6}>
        <LeftSide />
      </Grid>
      <Grid container xs={12} md={7} lg={6} className="right-side-container">
        <RightSide />
      </Grid>
    </Grid>
  );
};
export default SmallIntro;
