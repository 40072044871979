import React from 'react';
import PageWrapper from '../components/PageWrapper';
import TextSides from '../components/TextSides';
import CardSides from '../components/CardSide';
import Benifits from '../components/Benifits';
import Steps from '../components/steps';
import Partners from '../components/Partners';
import { Helmet } from 'react-helmet';
import SmallIntro from '../components/SmallIntro';

export default function Home() {
  return (
    <div className='page-home'>
      <Helmet>
        <title>
          Click & Fi - Solutions de location et de financement pour vos projets
          auto
        </title>
        <meta
          name='description'
          content='Trouvez les meilleures solutions de location et de financement pour vos projets auto avec Click & Fi. Nos conseillers experts vous accompagnent tout au long de votre projet pour vous aider à trouver le modèle idéal et à optimiser vos coûts.'
        />
        <link rel='canonical' href='https://www.click-and-fi.fr' />
      </Helmet>
      <PageWrapper>
        {/* <Intro /> */}
        <SmallIntro />
        <TextSides />
        <CardSides />
        <Benifits />
        <Steps />
        <Partners />
      </PageWrapper>
    </div>
  );
}
