import React from 'react';
import Icon from './Icon';
import Grid from './grid';
import { Link } from 'react-router-dom';

export default function TextSides() {
  return (
    <div className='text-sides-wrapper'>
      <Grid container maxWidth='lg' className='text-sides'>
        <Grid
          className='left-side'
          xs={12}
          md={12}
          sx={{
            my: {
              md: 6,
              sm: 2,
            },
          }}
        >
          <h2>
            Conciergerie dédiée
            <br /> à votre <span>mobilité Automobile</span>
          </h2>
          <p>
            <b>
              {' '}
              Tic, tac, tic, tac ... soyez prêt(e) pour votre prochain projet
              automobile !
            </b>
          </p>
        </Grid>
        <Grid className='right-side' xs={12} md={12}>
          <div className='p-container'>
            <p>
              Votre contrat de location automobile arrive bientôt à échéance et
              il est grand temps de tourner la page. Vous explorez déjà tous les
              sites Internet pour être certain de faire le bon choix et opter
              pour le véhicule qui correspond à vos nouvelles envies ?
              <br />
              <p className='highlight'>
                <b>
                  Découvrez dès à présent nos solutions pour votre véhicule et
                  faites de belles économies
                </b>
              </p>
            </p>
          </div>
          <div className='promo-wrapper'>
            <div className='promo'>
              <Link to='/prolonger' className='promo-inner'>
                <p>
                  Jusqu’à <span>-30%</span> sur vos loyers*
                </p>
                <div className='icon-wrapper'>
                  <Icon icon='ArrowRight' size={20} />
                </div>
              </Link>
            </div>
            <p className='info'>
              (*) la baisse du montant des loyers suppose un allongement de la
              durée de votre contrat de location en cours et votre nouveau
              kilométrage défini ensemble.
            </p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
