import React from "react";
import Grid from "../components/grid";

export default function Steps() {
  return (
    <Grid
      container
      maxWidth="xlg"
      className="steps-wrapper"
    >
      <Grid container maxWidth="lg" className="steps">
        <Grid sm={12} md={12} className="title">
          <h2>
            <span className="small">
              Nos différentes <b>étapes</b>
            </span>
            <span className="larg">
              pour les <span className="highlight">différentes </span>
              SOLUTIONS DE CLICK & FI
            </span>
          </h2>
        </Grid>
        <Grid sm={12} md={12} className="cards">
          <div className="card">
            <div className="card-content">
              <p className="card-title">
                <span className="number">1</span>
                <span className="t-text">Complétez votre demande</span>
              </p>
              <p className="card-body">2 minutes suffisent pour déposer votre demande en complétant notre formulaire</p>
            </div>
          </div>
          <div className="card">
            <div className="card-content">
              <p className="card-title">
                <span className="number">2</span>
                <span className="t-text">Étude du dossier</span>
              </p>
              <p className="card-body">
                Votre dossier est étudié par nos conseillers experts en
                automobile.
              </p>
            </div>
          </div>
          <div className="card">
            <div className="card-content">
              <p className="card-title">
                <span className="number">3</span>
                <span className="t-text">Proposition</span>
              </p>
              <p className="card-body">
                Nous recherchons la meilleure solution pour vous.
              </p>
            </div>
          </div>
          <div className="card">
            <div className="card-content">
              <p className="card-title">
                <span className="number">4</span>
                <span className="t-text"></span>
                Finalisation
              </p>
              <p className="card-body">
                Profitez de votre nouvelle location automobile en toute
                sérénité.
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}
