import React from 'react';
import BlockForm from '../components/blockForm';
import Head from '../components/head';
import BlockImage from '../components/blockImage';
import PageWrapper from '../components/PageWrapper';
import Button from '../components/button';
import FormRenew from '../components/formRenew';
import { Helmet } from 'react-helmet';
import Items from '../components/items';

export default function RenewPage() {
  return (
    <div className='page-renew'>
      <Helmet>
        <title>Renouveler - Click & Fi</title>
        <meta
          name='description'
          content='Renouvelez votre contrat de location et profitez de nouveaux avantages avec Click & Fi. Nous vous accompagnons pour trouver le modèle idéal et optimiser vos coûts.'
        />
        <link rel='canonical' href='https://www.click-and-fi.fr/renouveler' />
      </Helmet>
      <PageWrapper>
        <Head
          color='green'
          anchorContent='Renouvelez'
          anchorStyle={{ backgroundColor: '#ce9f69' }}
        >
          <>
            <span className='bottom'>
              <b>
                CONFIGUREZ VOTRE NOUVEAU VEHICULE EN LOCATION AVEC NOS EXPERTS
              </b>
            </span>
          </>
        </Head>
        <BlockImage>
          <div className='content'>
            <div className='block-head'>
              <h3>
                <b>
                  <span className='bottom'>
                    <span className='highlight'>RENOUVELER</span>
                    <br /> AVEC CLICK & FI
                  </span>
                </b>
              </h3>
            </div>
            <div className='block-body'>
              <p>
                Votre contrat de location automobile arrive bientôt à échéance
                et vous souhaitez le renouveler ? C’est le moment de prendre les
                devants pour concrétiser votre projet sereinement ! Profitez
                d’un accompagnement sur mesure et bénéficiez d’une solution de
                financement adaptée à vos besoins pour l’obtention de votre
                prochain véhicule.
              </p>
              <br />
              <Button
                green
                text='Je souhaite être accompagné'
                to='#form-renouveler'
              />
            </div>
          </div>
          <img src='/images/block-car.jpeg' alt='block-image' />
        </BlockImage>
        <Items />
        <BlockForm>
          <div className='content content-two' id='form'>
            <div className='block-head'>
              <h3>
                <b>
                  Comment <br />
                  ça marche ?
                </b>
              </h3>
            </div>
            <div className='block-body'>
              <br />
              <br />
              <b>1 - Remplissez le formulaire de contact</b>
              <p>
                Renseignez au préalable vos informations de contact et le budget
                que vous souhaitez allouer à votre nouvelle location automobile.
                Ainsi, nos experts peuvent mieux cerner votre projet. En plus,
                c’est vous qui choisissez vos disponibilités pour être
                recontacté(e).
              </p>
              <br />
              <br />
              <b>2 - Profitez de notre expertise</b>
              <p>
                Pour vous assurer de faire le meilleur choix possible, que vous
                ayez un véhicule en tête ou non, l’un de nos conseillers revient
                vers vous afin de vous accompagner dans la réalisation de votre
                projet. Vous obtenez alors une proposition de financement
                adaptée à vos besoins et votre budget. Le véhicule dont vous
                avez toujours rêvé est à votre portée !
              </p>
              <br />
              <br />
              <b>3 - Finalisez votre demande</b>
              <p>
                Dès que votre demande de financement est validée, vous profitez
                d’une disponibilité rapide de votre véhicule. La concession la
                plus proche de chez vous, vous contacte rapidement pour que vous
                puissiez découvrir votre automobile et finaliser les démarches
                administratives.
              </p>
              <br />
              <br />
              <b>
                Découvrez dès maintenant LA solution de financement qu’il vous
                faut !
              </b>
            </div>
          </div>
          <FormRenew />
        </BlockForm>
      </PageWrapper>
    </div>
  );
}
