import React from "react";
import Grid from "./grid";

interface Props {
  children: Array<React.ReactNode>;
  reverse?: boolean;
}

export default function BlockImage({ children, reverse }: Props) {
  return (
    <Grid
      container
      direction={reverse ? "row-reverse" : "row"}
      maxWidth="lg"
      className="block-image"
      columnSpacing={8}
    >
      <Grid md={6} xs={12} className="text-block">
        {children[0]}
      </Grid>
      <Grid
        md={6}
        xs={12}
        className={`image-block${reverse ? " flex-start" : " flex-end"}`}
      >
        {children[1]}
      </Grid>
    </Grid>
  );
}
