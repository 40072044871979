import React from 'react';
import PageWrapper from '../components/PageWrapper';
import Head from '../components/head';
import Grid from '../components/grid';
import { Helmet } from 'react-helmet';

export default function LegalPage() {
  return (
    <div className='page-legal'>
      <Helmet>
        <title>Mentions légales - Click & Fi</title>
        <meta
          name='description'
          content="En savoir plus sur nos mentions légales et nos conditions générales d'utilisation. Nous sommes déterminés à offrir les meilleures solutions de location et de financement pour vos projets auto."
        />
        <link
          rel='canonical'
          href='https://www.click-and-fi.fr/mentions-legales'
        />
      </Helmet>
      <PageWrapper>
        <Head>
          <b>MENTIONS LEGALES</b>
        </Head>
        <Grid container spacing={4} maxWidth='lg'>
          <Grid sm={12} className='text-privacy'>
            <div>
              <p>
                Le site{' '}
                <a
                  href='http://www.click-and-fi.fr/'
                  target='_blank'
                  rel='noreferrer'
                >
                  www.click-and-fi.fr
                </a>{' '}
                (ci-apr&egrave;s, le &laquo; <strong>Site</strong> &raquo;) est
                &eacute;dit&eacute; par la soci&eacute;t&eacute; SIMPLY-FY,
                soci&eacute;t&eacute; par actions simplifi&eacute;e au capital
                de 54.400&euro;, immatricul&eacute;e au RCS de Dijon sous le
                n&deg; 890 392 509, dont le si&egrave;ge social est situ&eacute;
                8F, rue Jeanne Barret &ndash; 21000 Dijon, (ci-apr&egrave;s,
                l&rsquo; &laquo; <strong>&Eacute;diteur </strong>&raquo;).
              </p>
            </div>
            <div>
              <p>
                L&rsquo;&Eacute;diteur peut &ecirc;tre contact&eacute; &agrave;
                l&rsquo;adresse email contact@simply-fy.com.
              </p>
            </div>
            <div>
              <p>
                Le num&eacute;ro de TVA Intracommunautaire de
                l&rsquo;&Eacute;diteur est le : FR71949846653
              </p>
            </div>
            <div>
              <p>
                SIMPLY-FY est immatricul&eacute;e &agrave; l&rsquo;ORIAS
                (mandataire d&rsquo;interm&eacute;diaire en assurance et
                mandataire d&rsquo;interm&eacute;diaire en op&eacute;rations de
                banque) sous le num&eacute;ro 20008379 (
                <a href='http://www.orias.fr/' target='_blank' rel='noreferrer'>
                  www.orias.fr
                </a>
                ), adh&eacute;rente &agrave; l&rsquo;association
                fran&ccedil;aise des interm&eacute;diaires en bancassurance
                (AFIB)
              </p>
            </div>
            <div>
              <p>
                <strong>HEBERGEUR DU SITE INTERNET</strong>
              </p>
            </div>
            <div>
              <p>
                Le Site est h&eacute;berg&eacute; par OVH, dont le si&egrave;ge
                social est situ&eacute; 2, rue Kellermann &ndash; 59100 Roubaix.
              </p>
            </div>
            <div>
              <p>
                L&rsquo;h&eacute;bergeur est joignable au num&eacute;ro suivant
                : 08 99 70 17 61 et &agrave; l&rsquo;adresse email{' '}
                <a
                  href='mailto:support@ovh.net'
                  target='_blank'
                  rel='noreferrer'
                >
                  support@ovh.net
                </a>
                .
              </p>
            </div>
            <div>
              <p>
                <strong>
                  RESPECT DE LA PROPRIETE INTELLECTUELLE &amp; CREDITS
                </strong>
              </p>
            </div>
            <div>
              <p>
                Toutes les marques, photographies, textes, commentaires,
                illustrations, images anim&eacute;es ou non, s&eacute;quences
                vid&eacute;o, sons, ainsi que toutes les applications
                informatiques qui pourraient &ecirc;tre utilis&eacute;es pour
                faire fonctionner le Site et plus g&eacute;n&eacute;ralement
                tous les &eacute;l&eacute;ments reproduits ou utilis&eacute;s
                sur le Site sont prot&eacute;g&eacute;s par les lois en vigueur
                au titre de la propri&eacute;t&eacute; intellectuelle.
              </p>
            </div>
            <div>
              <p>
                Ils sont la propri&eacute;t&eacute; pleine et enti&egrave;re de
                l&apos;&Eacute;diteur ou de ses partenaires, sauf mentions
                particuli&egrave;res et notamment les cr&eacute;dits suivants :
              </p>
            </div>
            <div>
              <p>
                <strong>Page d&apos;accueil</strong>
                <br />
                <br />
                L&eacute;gende : Une voiture de sport bleue gar&eacute;e sur le
                bord d&rsquo;une route
              </p>
            </div>
            <div>
              <p>
                Cr&eacute;dits : @mandacreatespretty / Unsplash
                <br />
                <br />
                <strong>Comment &ccedil;a marche ?</strong>
              </p>
            </div>
            <div>
              <p>
                L&eacute;gende : Happy young colleagues sitting in office
                coworking using laptop
              </p>
            </div>
            <div>
              <p>Cr&eacute;dits : drobotdean / Freepik</p>
            </div>
            <div>
              <p>
                <strong>Qui sommes-nous ?</strong>
              </p>
            </div>
            <div>
              <p>
                L&eacute;gende : Happy young colleagues sitting in office
                coworking using laptop
              </p>
            </div>
            <div>
              <p>Cr&eacute;dits : drobotdean / Freepik</p>
            </div>
            <div>
              <p>
                L&eacute;gende : Couleur Argent Mini Coup&eacute; Sur La Route.
                Conduisez Sous Le Soleil.
              </p>
            </div>
            <div>
              <p>Cr&eacute;dits : azerbaijan_stockers / Freepik</p>
            </div>
            <div>
              <p>
                <strong>Prolonger</strong>
              </p>
            </div>
            <div>
              <p> Légende : Close up business people hand shake </p>
            </div>
            <div>
              <p>Crédits : Image par Freepik</p>
            </div>
            <div>
              <p>
                L&eacute;gende : Happy young colleagues sitting in office
                coworking using laptop
              </p>
            </div>
            <div>
              <p>Crédits : drobotdean / Freepik</p>
            </div>
            <div>
              <p>
                <strong>Renouveler</strong>
              </p>
            </div>
            <div>
              <p>Légende : Une voiture argentée garée sur un chemin de terre</p>
            </div>
            <div>
              <p>Crédits : Michail Dementiev (@bot_va) / Unsplash</p>
            </div>
            <div>
              <p>
                <strong>Page d&apos;erreur</strong>
              </p>
            </div>
            <div>
              <p>Légende : Free photo young man checking the car engine</p>
            </div>
            <div>
              <div>
                <p>Crédits : Image par Freepik</p>
              </div>
              <p>
                Toute reproduction, repr&eacute;sentation, utilisation ou
                adaptation, sous quelque forme que ce soit, de tout ou partie de
                ces &eacute;l&eacute;ments, y compris les applications
                informatiques, sans l&apos;accord pr&eacute;alable et
                &eacute;crit de l&apos;&Eacute;diteur, sont strictement
                interdites. Le fait pour l&apos;&Eacute;diteur de ne pas engager
                de proc&eacute;dure d&egrave;s la prise de connaissance de ces
                utilisations non autoris&eacute;es ne vaut pas acceptation
                desdites utilisations et renonciation aux poursuites.
              </p>
            </div>
            <div>
              <p>
                Seule l&apos;utilisation pour un usage priv&eacute; dans un
                cercle de famille est autoris&eacute;e et toute autre
                utilisation est constitutive de contrefa&ccedil;on et/ou
                d&apos;atteinte aux droits voisins, sanctionn&eacute;es par Code
                de la propri&eacute;t&eacute; intellectuelle. La reprise de tout
                ou partie de ce contenu n&eacute;cessite l&apos;autorisation
                pr&eacute;alable de l&apos;&Eacute;diteur ou du titulaire des
                droits sur ce contenu.
              </p>
            </div>
            <div>
              <p>
                <strong>LIENS HYPERTEXTES</strong>
              </p>
            </div>
            <div>
              <p>
                Le Site peut contenir des liens hypertexte donnant acc&egrave;s
                &agrave; d&apos;autres sites web &eacute;dit&eacute;s et
                g&eacute;r&eacute;s par des tiers et non par
                l&apos;&Eacute;diteur. L&apos;&Eacute;diteur ne pourra
                &ecirc;tre tenu responsable directement ou indirectement dans le
                cas o&ugrave; lesdits sites tiers ne respecteraient pas les
                dispositions l&eacute;gales.&nbsp;
              </p>
            </div>
            <div>
              <p>
                La cr&eacute;ation de liens hypertexte vers le Site ne peut
                &ecirc;tre faite qu&apos;avec l&apos;autorisation &eacute;crite
                et pr&eacute;alable de l&apos;&Eacute;diteur.
              </p>
            </div>
            <div>
              <p>
                <strong>COLLECTE DE DONNEES PERSONNELLES&nbsp;</strong>
              </p>
            </div>
            <div>
              <p>
                Conform&eacute;ment aux dispositions de la loi n&deg; 78-17 du 6
                janvier 1978 modifi&eacute;e, relative &agrave;
                l&apos;informatique, aux fichiers et aux libert&eacute;s,
                l&rsquo;utilisateur est inform&eacute; que l&apos;&Eacute;diteur
                proc&egrave;de &agrave; la collecte et au traitement de
                donn&eacute;es personnelles, lors de la connexion au Site.&nbsp;
              </p>
            </div>
            <div>
              <p>
                Les donn&eacute;es collect&eacute;es servent principalement
                &agrave; permettre la mise &agrave; disposition de la
                plateforme, son optimisation et sa s&eacute;curisation afin
                d&rsquo;offrir aux utilisateurs un service de la meilleure
                qualit&eacute; possible.&nbsp;
              </p>
            </div>
            <div>
              <div>
                <p>
                  Des donn&eacute;es suppl&eacute;mentaires pourront
                  &eacute;ventuellement &ecirc;tre collect&eacute;es par
                  l&rsquo;&Eacute;diteur pour permettre la bonne
                  ex&eacute;cution de contrats commerciaux conclus par
                  l&rsquo;entremise de la plateforme avec l&rsquo;utilisateur.
                </p>
              </div>
              <div>
                <p>
                  L&rsquo;utilisateur est inform&eacute; qu&rsquo;il dispose
                  d&apos;un droit d&apos;acc&egrave;s, d&apos;interrogation, de
                  modification et de suppression des informations qui le
                  concerne, &agrave; exercer &agrave; tout moment aupr&egrave;s
                  de l&apos;&Eacute;diteur soit directement sur le Site, soit
                  par courrier postal adress&eacute; au 8F, rue Jeanne Barret
                  &ndash; 21000 Dijon, soit par e-mail &agrave; l&rsquo;adresse
                  contact@simply-fy.com.
                </p>
              </div>
              <div>
                <p>
                  Les informations recueillies pourront &eacute;ventuellement
                  &ecirc;tre partag&eacute;es &agrave; des tiers r&eacute;sidant
                  dans l&rsquo;Union Europ&eacute;enne dans les cas suivants
                  :&nbsp;
                </p>
              </div>
              <div>
                <ul>
                  <li>
                    Lorsque l&rsquo;utilisateur publie, dans une zone de
                    commentaire, des informations accessibles au public ;
                  </li>
                  <li>
                    Lorsque l&rsquo;utilisateur y consent express&eacute;ment ;
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <div>
                <ul>
                  <li>
                    Lorsque la loi l&rsquo;exige ou afin de coop&eacute;rer
                    &agrave; une enqu&ecirc;te judiciaire &agrave; la demande
                    expr&egrave;s d&rsquo;un d&eacute;tenteur de
                    l&rsquo;autorit&eacute; publique ;
                  </li>
                  <li>
                    Pour l&rsquo;ex&eacute;cution de prestations commerciales
                    pour lesquelles est rendu n&eacute;cessaire la
                    coop&eacute;ration d&rsquo;un tiers, notamment dans le cas
                    o&ugrave; l&rsquo;&Eacute;diteur choisirait de sous-traiter
                    certaines de ses prestations par l&rsquo;entremise de
                    services tiers.
                  </li>
                </ul>
              </div>
              <div>
                <p>
                  Lorsque certaines informations sont obligatoires pour
                  acc&eacute;der &agrave; des fonctionnalit&eacute;s
                  sp&eacute;cifiques du Site, l&apos;&Eacute;diteur indiquera ce
                  caract&egrave;re obligatoire au moment de la saisie des
                  donn&eacute;es.
                </p>
              </div>
              <div>
                <p>
                  Les donn&eacute;es personnelles collect&eacute;es ne seront
                  conserv&eacute;es que le temps n&eacute;cessaire pour
                  permettre la bonne utilisation du Site, emp&ecirc;cher les
                  fraudes et abus, et satisfaire aux obligations l&eacute;gales
                  et r&eacute;glementaires de l&rsquo;&Eacute;diteur concernant
                  la gestion du Site.
                </p>
              </div>
              <div>
                <p>Site développé par AGENT X</p>
              </div>
            </div>
          </Grid>
        </Grid>
      </PageWrapper>
    </div>
  );
}
