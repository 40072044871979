import React from 'react';
import PageWrapper from '../components/PageWrapper';
import Head from '../components/head';
import Grid from '../components/grid';
import { Helmet } from 'react-helmet';

export default function PolitiqueDeCookies() {
  return (
    <div className='page-legal'>
      <Helmet>
        <title>Politique des cookies - Click & Fi</title>
        <meta
          name='description'
          content='En savoir plus sur notre politique en matière de cookies.'
        />
        <link
          rel='canonical'
          href='https://www.click-and-fi.fr/politique-de-cookies'
        />
      </Helmet>
      <PageWrapper>
        <Head>
          <b>POLITIQUE DES COOKIES </b>
        </Head>
        <Grid container spacing={4} maxWidth='lg'>
          <Grid sm={12} className='text-privacy'>
            <div>
              <p>
                <strong>QU&rsquo;EST-CE QU&rsquo;UN COOKIE ?</strong>
              </p>
            </div>
            <div>
              <p>
                Lors de votre navigation sur notre Site des cookies, pixels et
                autres traceurs (ci-apr&egrave;s d&eacute;sign&eacute;s ensemble
                les &laquo; <strong>Cookies</strong> &raquo;) sont
                d&eacute;pos&eacute;s sur votre navigateur.
              </p>
            </div>
            <div>
              <p>
                Un Cookie est un petit ﬁchier, souvent crypt&eacute;,
                stock&eacute; dans votre navigateur ou votre terminal et
                identiﬁ&eacute; par un nom. Il est d&eacute;pos&eacute; lors de
                la consultation d&rsquo;un site ou d&rsquo;une application.
                Chaque fois que vous revenez sur le site ou sur
                l&rsquo;application en question, le Cookie est
                r&eacute;cup&eacute;r&eacute; sur votre navigateur ou sur votre
                terminal. Ainsi, chaque fois que vous consultez le site ou
                l&rsquo;application, le navigateur est reconnu.
              </p>
            </div>
            <div>
              <p>
                Le d&eacute;p&ocirc;t de ces Cookies est susceptible de nous
                permettre d&rsquo;acc&eacute;der &agrave; vos donn&eacute;es de
                navigation et/ ou &agrave; des donn&eacute;es &agrave;
                caract&egrave;re personnel vous concernant.
              </p>
            </div>
            <div>
              <p>
                Cette page vous permet de mieux comprendre comment fonctionnent
                les cookies et comment utiliser les outils actuels afin de les
                param&eacute;trer.
              </p>
            </div>
            <div>
              <p>
                <strong>IDENTIFICATION DES COOKIES</strong>
              </p>
            </div>
            <div>
              <p>
                <em>Cookies techniques et fonctionnels</em>
              </p>
            </div>
            <div>
              <p>
                Les Cookies techniques et fonctionnels sont n&eacute;cessaires
                au bon fonctionnement du Site et pour vous fournir nos services.
                Ils sont utilis&eacute;s tout au long de votre navigation, aﬁn
                de la faciliter et d&rsquo;ex&eacute;cuter certaines fonctions.
              </p>
            </div>
            <div>
              <p>
                Un Cookie technique peut par exemple &ecirc;tre utilis&eacute;
                pour m&eacute;moriser vos r&eacute;ponses renseign&eacute;es
                dans un formulaire ou encore vos pr&eacute;f&eacute;rences
                s&rsquo;agissant de la langue ou de la pr&eacute;sentation du
                Site, lorsque de telles options sont disponibles. Sans ces
                Cookies vous ne pourrez utiliser normalement le site, nous vous
                d&eacute;conseillons de les supprimer.
              </p>
            </div>
            <div>
              <p>
                Nous utilisons les Cookies techniques et fonctionnels suivants :
              </p>
            </div>
            <div>
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <div>
                            <strong>Nom du Cookie</strong>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <strong>Fonction du Cookie</strong>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <strong>Dur&eacute;e de conservation</strong>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div>
                            <strong>
                              <em>Non applicable</em>
                            </strong>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <strong>
                              <em>Non applicable</em>
                            </strong>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <strong>
                              <em>Non applicable</em>
                            </strong>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <p>
                <em>Cookies publicitaires</em>
              </p>
            </div>
            <div>
              <p>
                Les Cookies publicitaires peuvent &ecirc;tre cr&eacute;&eacute;s
                non seulement par notre Site mais &eacute;galement par
                d&rsquo;autres sites internet et applications diﬀusant des
                publicit&eacute;s, annonces, widgets ou autres
                &eacute;l&eacute;ments sur la page aﬃch&eacute;e.
              </p>
            </div>
            <div>
              <p>
                Ces Cookies peuvent notamment servir &agrave; personnaliser et
                mesurer l&apos;eﬃcacit&eacute; de la publicit&eacute; ou encore
                &agrave; r&eacute;aliser du ciblage publicitaire.
              </p>
            </div>
            <div>
              <p>Nous utilisons les Cookies publicitaires suivants :</p>
            </div>
            <div>
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <div>
                            <strong>Nom du Cookie</strong>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <strong>Fonction du Cookie</strong>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <strong>Dur&eacute;e de conservation</strong>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div>
                            <strong>
                              <em>Non applicable</em>
                            </strong>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <strong>
                              <em>Non applicable</em>
                            </strong>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <strong>
                              <em>Non applicable</em>
                            </strong>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <p>
                <em>Cookies de personnalisation de contenu</em>
              </p>
            </div>
            <div>
              <p>
                Les Cookies de personnalisation de contenu nous permettent de
                vous proposer les contenus les plus susceptibles de correspondre
                &agrave; vos centres d&rsquo;int&eacute;r&ecirc;t au regard de
                votre proﬁl de navigation. Votre proﬁl de navigation est
                &eacute;tabli en fonction des contenus que vous
                d&eacute;j&agrave; avez consult&eacute;s.
              </p>
            </div>
            <div>
              <p>
                Nous utilisons les Cookies de personnalisation de contenu
                suivants :
              </p>
            </div>
            <div>
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <div>
                            <strong>Nom du Cookie</strong>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <strong>Fonction du Cookie</strong>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <strong>Dur&eacute;e de conservation</strong>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div>
                            <strong>
                              <em>Non applicable</em>
                            </strong>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <strong>
                              <em>Non applicable</em>
                            </strong>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <strong>
                              <em>Non applicable</em>
                            </strong>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <p>
                <em>Cookies de r&eacute;seaux sociaux</em>
              </p>
            </div>
            <div>
              <p>
                Les Cookies de r&eacute;seaux sociaux vous permettent de
                partager des contenus de notre Site sur les r&eacute;seaux
                sociaux et de faire conna&icirc;tre, sur ces r&eacute;seaux,
                votre opinion ou votre consultation de nos Services en cliquant
                notamment sur les liens &laquo; j&rsquo;aime &raquo; et &laquo;
                partager &raquo;.
              </p>
            </div>
            <div>
              <p>
                Ces Cookies peuvent &eacute;galement permettre de tracer la
                navigation des utilisateurs sur le Site.
              </p>
            </div>
            <div>
              <p>
                Nous utilisons les Cookies de r&eacute;seaux sociaux suivants :
              </p>
            </div>
            <div>
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <div>
                            <strong>Nom du Cookie</strong>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <strong>Fonction du Cookie</strong>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <strong>Dur&eacute;e de conservation</strong>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div>Facebook Pixel</div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>Identifie les visiteurs de Facebook</div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>13 mois maximum</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div>Facebook Conversion Tracking</div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            Analyser les performances des publicit&eacute;s FB
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>13 mois maximum</div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <p>
                <em>Cookies analytiques</em>
              </p>
            </div>
            <div>
              <p>
                Ces Cookies nous permettent de mesurer le nombre de visites, le
                nombre de pages vues et l&rsquo;activit&eacute; des
                utilisateurs. Ils peuvent le cas &eacute;ch&eacute;ant collecter
                votre adresse IP pour d&eacute;terminer la ville depuis laquelle
                vous vous connectez. Les Cookies analytiques nous permettent de
                g&eacute;n&eacute;rer des statistiques de fr&eacute;quentation
                et de navigation de notre Site aﬁn d&rsquo;am&eacute;liorer nos
                performances.&nbsp;
              </p>
            </div>
            <div>
              <p>
                Les Cookies utilis&eacute;s permettent &eacute;galement
                d&rsquo;identiﬁer les probl&egrave;mes de navigation et &agrave;
                terme, de les r&eacute;gler.
              </p>
            </div>
            <div>
              <p>Nous utilisons les Cookies analytiques suivants :</p>
            </div>
            <div>
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td>
                        <div>
                          <div>
                            <strong>Nom du Cookie</strong>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <strong>Fonction du Cookie</strong>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            <strong>Dur&eacute;e de conservation</strong>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div>Google Analytics</div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>Mesure d&rsquo;audience</div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>13 mois maximum</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div>Google Tag Manager</div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>Syst&egrave;me de gestion de balisage</div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>13 mois maximum</div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>
                          <div>Google Ads</div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>
                            Mesure les performances des campagnes Google
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div>13 mois maximum</div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div>
              <p>
                <strong>VOS PREFERENCES EN MATIERE DE COOKIES</strong>
              </p>
            </div>
            <div>
              <p>
                <em>
                  Cookies pouvant &ecirc;tre d&eacute;pos&eacute;s sans
                  consentement
                </em>
              </p>
            </div>
            <div>
              <p>
                Certains Cookies ne n&eacute;cessitent pas votre consentement,
                c&rsquo;est le cas des :
              </p>
            </div>
            <div>
              <div>
                <ul>
                  <li>
                    Cookies techniques et fonctionnels qui sont
                    n&eacute;cessaires au fonctionnement du Site ; et
                  </li>
                  <li>
                    De certains Cookies de mesure d&rsquo;audience ou des
                    Cookies qui permettent de tester des versions
                    diﬀ&eacute;rentes du Site &agrave; des ﬁns
                    d&rsquo;optimisation des choix &eacute;ditoriaux.
                  </li>
                </ul>
              </div>
              <div>
                <p>
                  <em>
                    L&rsquo;acceptation ou le refus des Cookies soumis &agrave;
                    votre consentement express
                  </em>
                </p>
              </div>
            </div>
            <div>
              <p>
                Tous les autres Cookies n&eacute;cessitent votre consentement.
                Il s&rsquo;agit des Cookies publicitaires, de r&eacute;seaux
                sociaux, de personnalisation de contenu et de certains Cookies
                d&rsquo;analyse d&rsquo;audience.&nbsp;
              </p>
            </div>
            <div>
              <p>
                Vous pouvez choisir librement d&rsquo;accepter ou de refuser
                l&rsquo;utilisation de ces Cookies.
              </p>
            </div>
            <div>
              <p>
                Vous pouvez accepter ou refuser ces Cookies lors de votre
                premi&egrave;re navigation sur le Site.
              </p>
            </div>
            <div>
              <p>
                Vos choix d&rsquo;accepter ou de refuser les cookies seront
                conserv&eacute;s pendant une dur&eacute;e de six (6) mois.
              </p>
            </div>
            <div>
              <p>
                Vous &ecirc;tes libre de retirer votre consentement et plus
                g&eacute;n&eacute;ralement de modiﬁer vos
                pr&eacute;f&eacute;rences &agrave; tout moment, via le bandeau
                des cookies.
              </p>
            </div>
            <div>
              <p>
                <em>Le param&eacute;trage de votre navigateur</em>
              </p>
            </div>
            <div>
              <p>
                La plupart des navigateurs acceptent les Cookies par
                d&eacute;faut. Cependant, vous pouvez d&eacute;cider de bloquer
                ces cookies ou demander &agrave; votre navigateur de vous
                avertir lorsqu&rsquo;un site tente d&rsquo;impl&eacute;menter un
                cookie sur votre terminal.
              </p>
            </div>
            <div>
              <p>
                Attention, certaines fonctionnalit&eacute;s du Site peuvent ne
                plus fonctionner.
              </p>
            </div>
            <div>
              <p>
                Il est &eacute;galement possible de param&eacute;trer votre
                navigateur aﬁn qu&rsquo;il accepte ou refuse certains Cookies.
              </p>
            </div>
            <div>
              <p>
                La configuration de chaque navigateur est diff&eacute;rente.
                Elle est d&eacute;crite dans le menu d&rsquo;aide de votre
                navigateur, qui vous permettra de savoir de quelle
                mani&egrave;re modifier vos souhaits en mati&egrave;re de
                Cookies.
              </p>
            </div>
            <div>
              <p>
                Chaque navigateur propose des modalit&eacute;s de conﬁguration
                diﬀ&eacute;rentes :
              </p>
            </div>
            <div>
              <p>
                Pour Edge : rendez-vous dans le menu &laquo; Param&egrave;tres
                &raquo;, puis &laquo; Option Internet &raquo;. Cliquez sur
                &laquo; Conﬁdentialit&eacute; &raquo; puis sur &laquo;
                Param&egrave;tres de conﬁdentialit&eacute; avanc&eacute;s
                &raquo; ;
              </p>
            </div>
            <div>
              <p>
                Pour Chrome : rendez-vous dans le menu &laquo; Param&egrave;tres
                &raquo; puis cliquez sur &laquo; Conﬁdentialit&eacute; et
                S&eacute;curit&eacute; &raquo;. Cliquez sur &laquo; Autorisation
                &raquo; et &laquo; Cookies et donn&eacute;es du sites &raquo; ;
              </p>
            </div>
            <div>
              <p>
                Pour Safari : rendez-vous dans l&rsquo;application &laquo;
                R&eacute;glages &raquo; puis allez sur Safari. Cliquez sur
                l&rsquo;onglet &laquo; Conﬁdentialit&eacute; et
                S&eacute;curit&eacute; &raquo;. Vous pouvez d&eacute;sormais
                choisir de bloquer tous les Cookies.
              </p>
            </div>
            <div>
              <p>
                <em>Identiﬁants publicitaires</em>
              </p>
            </div>
            <div>
              <p>
                Des donn&eacute;es personnelles vous concernant peuvent
                &ecirc;tre collect&eacute;es en lien avec votre identiﬁant
                publicitaire.
              </p>
            </div>
            <div>
              <p>
                Chaque t&eacute;l&eacute;phone mobile poss&egrave;de un
                identiﬁant publicitaire qui lui est propre et qui permet aux
                annonceurs de diﬀuser de la publicit&eacute; cibl&eacute;e.
              </p>
            </div>
            <div>
              <p>
                Android et Apple permettent aux mobinautes, dans les
                r&eacute;glages, de ma&icirc;triser leur vie priv&eacute;e ce
                qui rend impossible le lien entre leur historique publicitaire
                et leurs prochaines navigations.{' '}
              </p>
            </div>
            <div>
              <p>
                Vous pouvez d&eacute;sactiver et r&eacute;initialiser &agrave;
                tout moment votre identiﬁant publicitaire, si vous ne souhaitez
                plus recevoir de publicit&eacute;s cibl&eacute;es :{' '}
              </p>
            </div>
            <div>
              <p>
                Sur iOS : allez dans les &quot;R&eacute;glages&quot;, puis dans
                &quot;Conﬁdentialit&eacute;&quot;, puis dans
                &quot;Publicit&eacute;&quot; puis dans
                &quot;R&eacute;initialiser l&apos;identiﬁant de
                publicit&eacute;&quot;;
              </p>
            </div>
            <div>
              <p>
                Sur Android : allez dans &quot;Param&egrave;tres Google&quot;
                (ou &quot;Param&egrave;tres&quot; puis &quot;Google&quot;), puis
                dans &quot;Annonces&quot;.
              </p>
            </div>
            <div>
              <p>
                Vous recevrez probablement la m&ecirc;me quantit&eacute; de
                publicit&eacute;s mais elles seront moins pertinentes.
              </p>
            </div>
            <div>
              <p>
                <strong>EN SAVOIR PLUS SUR LES COOKIES</strong>
              </p>
            </div>
            <div>
              <p>
                Pour plus d&rsquo;information sur les cookies, vous pouvez vous
                rendre sur le site de la CNIL, &agrave; cette adresse :{' '}
                <a
                  href='http://www.cnil.fr/vos-droits/vos-traces/les-cookies/'
                  target='_blank'
                  rel='noreferrer'
                >
                  http://www.cnil.fr/vos-droits/vos-traces/les-cookies/
                </a>
              </p>
            </div>
          </Grid>
        </Grid>
      </PageWrapper>
    </div>
  );
}
