import React from 'react';
import Grid from '../components/grid';

export default function Items() {
  return (
    <Grid container maxWidth="lg" className="items" rowSpacing={8}>
      <Grid sm={12} md={4}>
        <div className="item">
          <div className="item-content">
            <div className="icon">
              <img src="/images/icons/1.svg" alt="icon 1" />
            </div>
            <div className="item-text">
              <b>Suivi personnalisé </b>
              <p>
                Nos équipes vous accompagnent personnellement afin de vous
                conseiller au mieux et vous aider à faire des économies.
              </p>
            </div>
          </div>
        </div>
      </Grid>
      <Grid sm={12} md={4}>
        <div className="item">
          <div className="item-content">
            <div className="icon">
              <img src="/images/icons/2.svg" alt="icon 2" />
            </div>
            <div className="item-text">
              <b>Parcours digitalisé </b>
              <p>
                Les démarches de souscription sont simples et rapides, avec un
                parcours 100% digitalisé.
              </p>
            </div>
          </div>
        </div>
      </Grid>
      <Grid sm={12} md={4}>
        <div className="item">
          <div className="item-content">
            <div className="icon">
              <img src="/images/icons/3.svg" alt="icon 3" />
            </div>
            <div className="item-text">
              <b>Des contrats ajustables et personnalisables</b>
              <p>
                Piochez uniquement ce qui correspond à votre besoin dans notre
                offre complète :entretien - garantie perte financière - option
                pneumatiques - assurance tous risques. Profitez également d’une
                flexibilité à toute épreuve sur la durée et le kilométrage.
              </p>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
}
