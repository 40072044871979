import React from 'react';
import PageWrapper from '../components/PageWrapper';
import Head from '../components/head';
import BlockImage from '../components/blockImage';
import { Helmet } from 'react-helmet';

export default function About() {
  return (
    <div className='page-guid'>
      <Helmet>
        <title>Qui sommes nous ? - Click & Fi</title>
        <meta
          name='description'
          content='Meta description: En savoir plus sur Click & Fi, notre équipe et nos valeurs. Nous sommes déterminés à offrir les meilleures solutions de location et de financement pour vos projets auto.'
        />
        <link
          rel='canonical'
          href='https://www.click-and-fi.fr/qui-sommes-nous'
        />
      </Helmet>
      <PageWrapper>
        <Head>
          <b>Qui sommes-nous ?</b>
        </Head>
        <BlockImage>
          <div className='content content-two'>
            <div className='block-head'>
              <h3>
                <b>
                  <span className='bottom'>
                    CONCIERGERIE DÉDIÉE À VOTRE
                    <span className='highlight'> MOBILITÉ AUTOMOBILE</span>
                  </span>
                </b>
              </h3>
            </div>
            <div className='block-body'>
              <p>
                Après plus de 30 années d’expérience dans le secteur de la
                distribution et du financement automobile, nous avons décidé de
                mettre notre savoir-faire au profit de la maitrise de votre
                budget automobile.
              </p>
              <br />
              <p>
                Parce que coût d’usage d’un véhicule n’a jamais été aussi
                important dans votre budget global : Objectif préserver votre
                pouvoir d’achat.
              </p>
              <br />
              <p>
                Parce qu’il existe d’autres solutions à celles qu’on vous
                présente.
              </p>
              <br />
              <p>
                La raison d’être de notre conciergerie Click & Fi :{' '}
                <span className='highlight'>
                  préserver votre mobilité automobile.
                </span>
              </p>
            </div>
          </div>
          <img src='/images/blockimage2.jpeg' alt='block-image' />
        </BlockImage>
        <BlockImage reverse>
          <div className='content content-two'>
            <div className='block-head'>
              <h3>
                <span className='highlight'>LES SERVICES </span>
                DE LA CONCIERGERIE CLICK & FI
              </h3>
            </div>
            <div className='block-body'>
              <p>
                Véritable conciergerie, Click & Fi vous accompagne dans votre
                projet automobile, quel qu’il soit.
              </p>
              <br />
              <p className='highlight'>
                Vous avez envie de Renouveler votre véhicule actuel ? <br />
                Vous avez envie de Prolonger votre location en cours ? <br />
                Vous avez envie de faire reprendre votre véhicule ?<br />
              </p>
              <br />
              <p>
                Nos experts sont à votre disposition pour vous aider à
                concrétiser votre projet.
              </p>
              <br />
              <p>
                Contactez-nous{' '}
                <a href='mailto:conciergerie-auto@clickandfi.com<'>
                  conciergerie-auto@clickandfi.com
                </a>
              </p>
            </div>
          </div>
          <img src='/images/blockimage3.jpeg' alt='block-image' />
        </BlockImage>
      </PageWrapper>
    </div>
  );
}
