import APIs from "../config/index.js";
import axios from "axios";

const { PARTENER } = APIs;

if(!PARTENER) {
  console.error("PARTENER API is not defined")
}

const Partner = (code: string) => {
  return axios
    .get(PARTENER + code)
};


export {Partner}
