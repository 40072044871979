import React from "react";
import Grid from "./grid";

interface Props {
  children: Array<React.ReactNode>;
}

export default function BlockForm({ children }: Props) {
  return (
    <Grid container maxWidth="lg" className="block-form">
      <Grid md={5} xs={12} className="text-block">
        {children[0]}
      </Grid>
      <Grid md={7} xs={12} className="form-block">
        {children[1]}
      </Grid>
    </Grid>
  );
}
