import React, { useState, useEffect } from 'react';
import PageWrapper from '../components/PageWrapper';
import Head from '../components/head';
import Grid from '../components/grid';
import Button from '../components/button';
import FormRenew from '../components/formRenew';
import FormProlong from '../components/formProlong';
import { getPartner } from '../shared/Partner';
import { useNavigate, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
// actions
import { startLoading, stopLoading } from '../redux/actions/appActions';

const MemberSpace = ({ dispatch }: any) => {
  const [activeForm, setActiveForm] = useState<null | string>(null);
  const [user, setUser] = useState<null | {
    color: string;
    logo: string;
    loaded: boolean | null;
  }>(null);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const target = params.get('target');
    if (target) {
      handleClick(target);
    }
  }, [location]);

  useEffect(() => {
    dispatch(startLoading());
    getPartner(true)
      .then((partner: any) => {
        if (!partner && !partner.code) {
          navigate('/connexion');
          dispatch(stopLoading());
        } else {
          setUser({ ...partner, loaded: true });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (user?.loaded) {
      dispatch(stopLoading());
    }
  }, [user]);

  const handleClick = (target: string, update?: boolean) => {
    setActiveForm(target);
    if (update) {
      window.history.replaceState(null, '', `?target=${target}`);
    }
    setTimeout(() => {
      const form = document.getElementById(`form-${target}`);
      if (form) {
        form.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }, 0);
  };

  return (
    <div className='page-member-space'>
      <Helmet>
        <title>Espace partenaire - Click & Fi</title>
        <meta
          name='description'
          content="Découvrez l'espace partenaire de Click & Fi, où vous pouvez profiter de nos offres spéciales et de solutions flexibles et adaptées à vos besoins."
        />
        <link
          rel='canonical'
          href='https://www.click-and-fi.fr/espace-partenaire'
        />
      </Helmet>
      <PageWrapper>
        <Head bgColor={user?.color} logo={user?.logo}>
          <span className='top'>Espace partenaire</span>
          <span className='bottom'>
            <b>Accès aux formulaires</b>
          </span>
        </Head>
        <Grid container className='cards-finance cards-finance-details'>
          <Grid xs={12} md={6}>
            <div className='card-finance-details'>
              <div className='content'>
                <h3 className='title'>
                  <span className='highlight'>RENOUVELER</span> <br /> AVEC
                  CLICK & FI
                </h3>
                <ul>
                  <li>
                    <b>1 - </b>Sélectionner la marque ou le type de véhicule
                    parmi l’ensemble des modèles proposés
                  </li>
                  <li>
                    <b>2 - </b>Location avec Option d’achat ou Location Longue
                    durée, simulez votre financement pour profiter de l’offre la
                    plus adaptée
                  </li>
                  <li>
                    <b>3 - </b>Un Conseiller Expert vous recontacte pour affiner
                    et finaliser votre demande
                  </li>
                  <li>
                    <b>4 -</b> Dès l’accord de votre financement, la concession
                    vous contacte pour voir votre véhicule et finaliser les
                    démarches administratives
                  </li>
                </ul>
              </div>
            </div>
          </Grid>
          <Grid
            xs={12}
            md={6}
            sx={{
              my: {
                md: 8,
                sm: 0,
              },
            }}
          >
            <div className='card-finance-details'>
              <div className='content'>
                <h3 className='title'>
                  <span className='highlight'>PROLONGER</span> <br /> AVEC CLICK
                  & FI
                </h3>
                <ul>
                  <li>
                    <b>1 -</b>
                    Définition du prix de vente : Il sera calculé sur la base du
                    solde de votre location en cours + les Frais de mise à
                    disposition (nouvelle carte grise et frais de démarches
                    administratives)
                  </li>
                  <li>
                    <b>2 - </b>Définition du nouveau couple Durée de location /
                    Kilométrage annuel
                  </li>
                  <li>
                    <b>3 - </b>Définition de la nouvelle valeur future minimum
                    garantie sur les bases du marché actuel des véhicules
                    d’occasion & de l’état standard de votre véhicule à
                    aujourd’hui
                  </li>
                  <li>
                    <b>4 </b>- Définition des nouvelles prestations souhaitées
                    (Contrat d’entretien / Prolongation de garantie / Assurance
                    à la personne)
                  </li>
                </ul>
              </div>
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          className='cards-finance'
          sx={{
            my: {
              xs: 4,
              md: 4,
            },
            px: {
              xs: 0,
            },
          }}
        >
          <Grid
            xs={12}
            md={6}
            sx={{
              mb: {
                xs: 4,
                md: 0,
              },
            }}
          >
            <div className='card-finance'>
              <div className='content'>
                <h3 className='title'>
                  <span className='bottom'>
                    Je <b>renouvelle</b>
                  </span>
                </h3>
                <Button
                  text='Aller au formulaire'
                  onPress={() => handleClick('renouveler', true)}
                />
              </div>
            </div>
          </Grid>
          <Grid xs={12} md={6}>
            <div className='card-finance'>
              <div className='content'>
                <h3 className='title'>
                  <span className='bottom'>
                    Je <b>prolonge</b>
                  </span>
                </h3>
                <Button
                  text='Aller au formulaire'
                  dark
                  onPress={() => handleClick('prolonger', true)}
                />
              </div>
            </div>
          </Grid>
          {activeForm === 'renouveler' && (
            <Grid
              xs={12}
              md={6}
              mdOffset={3}
              sx={{
                my: {
                  md: 14,
                },
                mx: {
                  sm: 2,
                  md: 'auto',
                },
              }}
            >
              <FormRenew spaceMember />
            </Grid>
          )}

          {activeForm === 'prolonger' && (
            <Grid
              xs={12}
              md={12}
              sx={{
                my: {
                  md: 14,
                },
                mx: {
                  sm: 2,
                  md: 4,
                },
              }}
            >
              <FormProlong spaceMember />
            </Grid>
          )}
        </Grid>
      </PageWrapper>
    </div>
  );
};
function mapStateToProps(state: any) {
  return { state };
}
export default connect(mapStateToProps)(MemberSpace);
