import React from "react";
import {SyncLoader} from "react-spinners";

export default function Loading() {
  return (
    <div className="loading-overlay">
      <SyncLoader
        color="#b9764f"
        loading={true}
        size={20}
        aria-label="Loading Spinner"
        data-testid="loader"
      />
    </div>
  );
}
