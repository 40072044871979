import React from 'react';
import Icon from './Icon';
import { useNavigate } from 'react-router-dom';

const Button = ({
  text,
  dark,
  green,
  to,
  outlined,
  onPress,
  submit,
  mb,
}: {
  text: string;
  dark: boolean;
  green: boolean;
  to?: string;
  onPress?: any;
  outlined: boolean;
  submit?: boolean;
  mb?: boolean;
}) => {
  const navigate = useNavigate();

  const getBackgroundColor = () => {
    if (dark) {
      return '#B9764F';
    } else if (outlined) {
      return 'transparent';
    } else if (green) {
      return '#59706D';
    } else {
      return '#CE9F69';
    }
  };

  const borderColor = outlined ? '#CE9F69' : 'transparent';
  return (
    <button
      type={submit ? 'submit' : 'button'}
      className={`btn${outlined ? ' btn-outlined' : ''}${mb ? ' btn-mb' : ''}`}
      style={{
        backgroundColor: getBackgroundColor(),
        border: `1px solid ${borderColor}`,
        color: outlined ? '#CE9F69' : 'white',
      }}
      onClick={() => {
        if (onPress) {
          onPress();
        } else if (to) {
          navigate(to);
        }
      }}
    >
      <div className='text-wrapper'>
        <span>{text}</span>
      </div>
      <div
        className='icon-wrapper'
        style={{
          borderColor: outlined ? '#CE9F69' : 'inhiret',
        }}
      >
        <Icon icon='ArrowRight' size={15} />
      </div>
    </button>
  );
};

Button.defaultProps = {
  dark: false,
  outlined: false,
  onPress: null,
  green: false,
};

export default Button;
